import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Components 

import DoctorsList from './doctors/DoctorsList';
import CreateDoctor from './doctors/CreateDoctor';
import SupportTicketsList from './supportTickets/SupportTicketsList';
import CreateSupportTicket from './supportTickets/CreateSupportTicket';
import HackersList from './hackers/hackers/HackersList';
import CreateHacker from './hackers/account/CreateHacker';

const Container: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const section = useAppSelector(state => state.navigation.section);
  const accountInfo = useAppSelector(state => state.meta.accountInfo); 

  return (

      <Box component="main" sx={{ 
          display: 'flex', 
          height: '100vh', 
          width: '90vw',
          right: 0, 
          overflowX: 'hidden',
          position: 'absolute',
          color: 'white' }}>

          { ((section === 'doctors') && (accountInfo?.main)) && <DoctorsList />}

          { section === 'createDoctor' && <CreateDoctor />}

          { section === 'supportTickets' && <SupportTicketsList />}

          { section === 'createSupportTicket' && <CreateSupportTicket />}

          { ((section === 'hackers') && (accountInfo?.main)) && <HackersList />}

          { ((section === 'createHacker') && (accountInfo?.main)) && <CreateHacker />}


      </Box>
  );

};

export default Container;