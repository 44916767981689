import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { setSearchSection, setSearchQuery } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { useTranslation } from 'react-i18next';

// Components 

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation(); 

    const searchQuery = useAppSelector((state) => state.fileExplorer.searchQuery); 

    const [text, setText] = useState(''); 

    // --

    useEffect(() => { 

        dispatch(setSearchQuery(text)); 

    },[text]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row-reverse',
            alignItems: 'center', 
            width: '24%',
            height: '100%',
            marginRight: '1%', 
            marginBottom: '1%',
            fontSize: 12, 
        }}>

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: '100%', 
            borderRadius: 2,
            backgroundColor: '#383A40',
        }}>
            <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: 'grey' }}/>
            <TextField
            id="standard-search"
            placeholder={t('search') || 'Search'}
            type="search"
            variant="standard"
            value={searchQuery}
            InputProps={{
                style: {fontSize: 12},
                disableUnderline: true, 
            }}
            onChange={(e) => setText(e.target.value)}
            sx={{
                marginLeft: 2,
                fontSize: 12,
                width: '80%',
                borderBottomColor: 'grey'
            }}
            />
        </Box>
    
     </Box>

    );

};

export default SearchBar;