import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components

import FolderIcon from '@mui/icons-material/Folder';

type Props = {
    _id?: string; 
    title: string;
    description: string;
    status?: string;
    folder?: string; 
    uploadedBy: string;
    relatedPatient?: string;
    createdAt?: Date;
    updatedAt?: Date;
};


const FolderRow: React.FC<Props> = ({ _id, title, description, status, folder, uploadedBy, relatedPatient, createdAt, updatedAt }) => {

    const dispatch = useAppDispatch(); 

    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile); 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 30,
            color: '#C2C5C8',
            backgroundColor: searchSection === _id ? '#4D4B59' : 'transparent',
            '&:hover': { backgroundColor: searchSection === _id ? '#4D4B59' : 'rgba(77, 75, 89, 0.5)' }
        }}>

            <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 1 }} />

            <Box sx={{ fontSize: 12, marginLeft: 1 }}>{title}</Box>

        </Box>

    );

};

export default FolderRow;