import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// --- 

export const getMetaPatientInfo = async (patientId: string) => {
    try {
        const content = { 
            patientId: patientId
        }; 

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients/list`, content, getConfig());

        return response;

    } catch (error) {
        console.error('Error retrieving meta patient info', error);
    };
}

export const getPatientsList = async () => {

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients`, getConfig());

        if (response.status === 200) { 

            const data = response.data.patients as Array<object>; 
            return data; 
              
        };

    } catch (error) {
        console.error('Error retrieving patient list', error);
    };

};

// -- 

export const getPatientInfo = async (patientId : string) => {
    
    try {
        const jwtToken = Cookies.get('jwt');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/doctor/patients/${patientId}`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`
              },
            }
          );

        if (response.status === 200) { 

            return response.data.patient; 
              
        };

    } catch (error) {
        console.error('Error retrieving patient list', error);
    };
};

// -- 

export const getDoctorName = async (doctorId: string) => {
    try {
        const jwtToken = Cookies.get('jwt');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/${doctorId}`,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                }
            }
        )

        if (response.status === 200) { 
            
            const data = response.data.fullName;
            return data; 
              
        };

    } catch (error) {
        console.error('Error retrieving pdoctor', error);
    };
};

// -- 

export const getDoctorPatientDocs = async (patientId : string) => {
    try {
        const jwtToken = Cookies.get('jwt');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/documents/${patientId}`,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                }
            }
        );

        if (response.status === 200) { 
            
            const data = response.data.files;
            return data; 
              
        };

    } catch (error) {
        console.error('Error retrieving patient document list', error);
    };

}; 

// -- 

export const createPatient = async (patient : any) => {

    try {

        const content = { 
            patient: patient
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/createPatient`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a patient', error);
    };

}; 