import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setSmokingStatus, setAlcoholConsumption, setExerciseFrequency, setDiet } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Lifestyle: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const smokingStatus  = useAppSelector((state) => state.createPatient.smokingStatus ); 
    const alcoholConsumption = useAppSelector((state) => state.createPatient.alcoholConsumption); 
    const exerciseFrequency = useAppSelector((state) => state.createPatient.exerciseFrequency); 
    const diet = useAppSelector((state) => state.createPatient.diet); 

    const [smokingStatusError, setSmokingStatusError] = useState(false); 
    const [alcoholConsumptionError, setAlcoholConsumptionError] = useState(false); 
    const [exerciseFrequencyError, setExerciseFrequencyError] = useState(false); 
    const [dietError, setDietError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'smokingStatus') { 

            dispatch(setSmokingStatus(input)); 

            dispatch(removeErroredField('smokingStatus'));

        } else if (section === 'alcoholConsumption') { 

            dispatch(setAlcoholConsumption(input)); 

            dispatch(removeErroredField('alcoholConsumption'));

        } else if (section === 'diet') { 

            dispatch(setDiet(input)); 

            dispatch(removeErroredField('diet'));

        } else if (section === 'exerciseFrequency') { 

            dispatch(setExerciseFrequency(input)); 

            dispatch(removeErroredField('exerciseFrequency'));

        }; 

    };

    // -- 

    useEffect(() => { 

        if (smokingStatus && alcoholConsumption && exerciseFrequency && diet) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (smokingStatus ||  alcoholConsumption ||  exerciseFrequency ||  diet) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[smokingStatus, alcoholConsumption, exerciseFrequency, diet]); 


    // -- 

    useEffect(() => { 

        erroredFields?.includes('smokingStatus') ? setSmokingStatusError(true) : setSmokingStatusError(false);
        erroredFields?.includes('alcoholConsumption') ? setAlcoholConsumptionError(true) : setAlcoholConsumptionError(false);
        erroredFields?.includes('exerciseFrequency') ? setExerciseFrequencyError(true) : setExerciseFrequencyError(false);
        erroredFields?.includes('diet') ? setDietError(true) : setDietError(false);

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('smokingStatus')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={smokingStatus }
                onChange={(e) => handleInput(e.target.value, 'smokingStatus')}
                fullWidth
                error={smokingStatusError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('alcoholConsumption')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={alcoholConsumption}
                onChange={(e) => handleInput(e.target.value, 'alcoholConsumption')}
                fullWidth
                error={alcoholConsumptionError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('exerciseFrequency')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={exerciseFrequency}
                onChange={(e) => handleInput(e.target.value, 'exerciseFrequency')}
                fullWidth
                error={exerciseFrequencyError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('diet')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={diet}
                onChange={(e) => handleInput(e.target.value, 'diet')}
                fullWidth
                error={dietError}
                variant="outlined"
            />
            
        </Box>
    );
};

export default Lifestyle;