import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
  section: string, 
  corrections: any[], 
  selectedFile: any,
  isCorrectionsOpened: boolean
}

const initialState: InitialState = {
  section: '', 
  corrections: [], 
  selectedFile: null,
  isCorrectionsOpened: false, 
};

export const correctFileSlice = createSlice({
  name: 'correctFile',
  initialState,
    reducers: {
    setSection: (state, action: PayloadAction<string>) => {
        state.section = action.payload
    },
    setCorrections: (state, action: PayloadAction<any>) => {
        state.corrections = action.payload 
    },
    setSelectedFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload 
    },
    setIsCorrectionsOpened: (state, action: PayloadAction<boolean>) => {
      state.isCorrectionsOpened = action.payload 
    },
    resetCorrectFile: (state) => { 

      state.section = '';
      state.corrections = []; 
      state.selectedFile = null; 
      state.isCorrectionsOpened = false; 

    } 
  },
});

export const {
  setSection, 
  setCorrections, 
  setSelectedFile, 
  setIsCorrectionsOpened, 
  resetCorrectFile
} = correctFileSlice.actions;

export default correctFileSlice.reducer;