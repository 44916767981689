

const menuItems = [

    { type: 'item', section: 'overview', text: 'Data', iconName: 'bubble_chart', iconColor: '#FFFFFF' },

    { type: 'header', text: 'Doctors'},

    { type: 'item', section: 'doctors', text: 'Doctors', iconName: 'groups', iconColor: '#FFFFFF' },
    { type: 'item', section: 'createDoctor', text: 'Add', iconName: 'add_circle', iconColor: '#FFFFFF' },

    // { type: 'header', text: 'Insurers'},

    // { type: 'item', section: 'insurers', text: 'Insurers', iconName: 'verified_user', iconColor: '#FFFFFF' },

    // { type: 'item', section: 'createInsurer', text: 'Create', iconName: 'personAddAlt1', iconColor: '#FFFFFF' }, 
    
    { type: 'header', text: 'Support'},

    { type: 'item', section: 'supportTickets', text: 'Tickets', iconName: 'toll', iconColor: '#FFFFFF' },

    { type: 'item', section: 'createSupportTicket', text: 'Create', iconName: 'add_circle_outline', iconColor: '#FFFFFF' },
    
    { type: 'header', text: 'History'},

    { type: 'item', section: 'logs', text: 'Logs', iconName: 'history', iconColor: '#FFFFFF' },

    { type: 'header', text: 'Hackers'},

    { type: 'item', section: 'hackers', text: 'Hackers', iconName: 'groups', iconColor: '#FFFFFF' },

    { type: 'item', section: 'createHacker', text: 'Create', iconName: 'add_circle_outline', iconColor: '#FFFFFF' },


];

export default menuItems; 