import React from 'react';
import { useState, useEffect, useRef } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../../../util/style/getRandomColor';


type Props = {
    children?: React.ReactNode;
    title?: string; 
};

const OptionRow: React.FC<Props> = ({ children, title }) => {

    const selectedFile = useAppSelector(state => state.overview.selectedFile); 
    const fileExtension = useAppSelector(state => state.overview.fileExtension); 

    const containerRef = useRef<HTMLDivElement | null>(null);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const handlePrint = () => {
        if (fileExtension === 'pdf' && iframeRef.current) {
          iframeRef.current.contentWindow?.print();
        } else if (fileExtension === 'docx' && containerRef.current) {
          const printWindow = window.open('', '_blank');
          printWindow?.document.write(containerRef.current.innerHTML);
          printWindow?.document.close();
          printWindow?.print();
          printWindow?.close();
        }
    };

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 20, 
            width: '95%',
            cursor: 'pointer',
            marginLeft: '5%',
            marginBottom: 2, 
        }}>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly', 
                width: 30,
                height: 30, 
                borderRadius: '50%',
                marginRight: 20, 
                backgroundColor: getRandomColor(), 
                color: 'white'
            }}>
                {children}

            </div>

            <Box>{title}</Box>
        </Box>

  );

};

export default OptionRow;