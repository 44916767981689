import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components

import Legend from './filesList/Legend';
import List from './filesList/List';
import CreateFolder from './folders/CreateFolder';

const FilesList: React.FC = () => {

    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 

    return (

        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: '70%', 
            }}>

            <Legend />

            { isOpened && <CreateFolder />}

            <List />
            
        </Box>

    );

};

export default FilesList;