// Doctor Sidebar menu

const doctorDashboardMenu = (t) => [

    { type: 'item', section: 'overview', text: 'overview', iconName: 'grid_on', iconColor: '#FFFFF' },

    // -- 

    { type: 'header', text: t('templates')},

    { type: 'item', section: 'selectTemplate', text: 'selectTemplate', iconName: 'description', iconColor: '#FFFFFF'}, 

    { type: 'item', section: 'createTemplate', text: 'createTemplate', iconName: 'note_add', iconColor: '#FFFFFF' },

    // -- 

    { type: 'header', text: t('files') },

    { type: 'item', section: 'uploadFile', text: 'uploadFile', iconName: 'file_upload', iconColor: '#FFFFFF' },

    { type: 'item', section: 'correctFile', text: 'correctFile', iconName: 'mode_edit', iconColor: '#FFFFFF' },

    // { type: 'item', section: 'transferFile', text: 'transferFile', iconName: 'send', iconColor: '#FFFFFF' },

    // -- 

    { type: 'header', text: t('betaFeatures') },

    { type: 'item', section: 'fileExplorer', text: t('fileExplorer'), iconName: 'schedule', iconColor: '#FFFFFF' },

    // { type: 'item', section: 'revokeFile', text: 'revokeFile', iconName: 'highlight_off', iconColor: '#FFFFFF' },

];

export default doctorDashboardMenu;