import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

// Components 

import EmptySpace from './fileExplorer/EmptySpace';
import Container from './fileExplorer/Container';

const FileExplorer: React.FC = () => {

    const { t } = useTranslation();
    
    return (

        <Box
          sx={{ 
          width: '100vw',
          height: '100vh',
          bottom: 0,
          right: 0,
          position: 'absolute', 
          overflow: 'none',
          zIndex: 5,
        }}>

          <EmptySpace />

          <Container />

        </Box>

    );

};

export default FileExplorer;