import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Icons 

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';

// Redux 

import { setCategories, setSection, setSectionSelected } from '../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Assets 

import createPatientCategories from '../../../../../util/dashboard/doctor/patient/createPatientCategories';

interface Patient {
  firstName: string;
  lastName: string;
  updatedAt: Date;
}

type Props = {
  children?: React.ReactNode;
};

const CategoriesList: React.FC<Props> = ({ children }) => {

  const ref = useRef() as any; 

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const erroredCategories = useAppSelector((state) => state.createPatient.erroredCategories) as Array<any>; 
  const erroredFields = useAppSelector((state) => state.createPatient.erroredFields) as Array<any>; 
  const completedCategories = useAppSelector((state) => state.createPatient.completedCategories) as Array<any>; 
  const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories) as Array<any>; 
  const section = useAppSelector((state) => state.createPatient.section);  

  const previousSection = useAppSelector((state) => state.createPatient.previousSection); 
  const nextSection = useAppSelector((state) => state.createPatient.nextSection); 

  const [categoriesList, setCategoriesList] = useState([]) as Array<any>;  

  // -- 

  const changeSection = (section: string) => {

    dispatch(setSection(section));

  }; 

  // -- 

  useEffect(() => { 

    dispatch(setCategories(createPatientCategories)); 
    setCategoriesList(createPatientCategories); 

  },[]);

  // -- 

  window.onkeydown = (ev: KeyboardEvent): any => {

    if ((ev.key === 'ArrowUp') || (ev.key === 'ArrowLeft')) { 

      if (previousSection) { 

        dispatch(setSection(previousSection));

      };

    } else if ((ev.key === 'ArrowDown') || (ev.key === 'ArrowRight')) {

      if (nextSection) { 

        dispatch(setSection(nextSection)); 

      }; 

    }; 

  }; 

  return (
    <Box sx={{         
      overflowY: 'auto',
      overflowX: 'hidden', 
      width: '95%', 
      minHeight: '75%',
      scrollbarWidth: 'none',
      marginTop: '1vh' }}
      >
      <List dense>
        {categoriesList?.map((item, index) => (
          <ListItem
            sx={{
              cursor: 'pointer',
              borderRadius: 1,
              backgroundColor: section === item ? '#4D4B59' : 'transparent',
              '&:hover': { backgroundColor: section === item ? '#4D4B59' : 'rgba(77, 75, 89, 0.3)' }
            }}
            onClick={() => changeSection(item)}
            key={index}
          >
            <ListItemText sx={{ color: section === item ? 'white' : '#C2C5C8' }} primary={t(item)} />
            <ListItemIcon sx={{ color: '#C2C5C8', alignItems: 'center', textAlign: 'center' }}>

              { (!completedCategories.includes(item) && !incompletedCategories.includes(item) && !erroredCategories.includes(item)) &&
                <CircleOutlinedIcon sx={{ color: '#C2C5C8', fontSize: section === item ? 23 : 20 }} />}

              { completedCategories.includes(item) && <CircleIcon sx={{ color: '#2ad12a', fontSize: section === item ? 23 : 20 }} />}
              { incompletedCategories.includes(item) && <CircleIcon sx={{ color: section === item ? 'rgb(174, 174, 174)': 'rgb(204, 107, 10)', fontSize: section === item ? 23 : 20 }} />}
              { erroredCategories.includes(item) && <CircleIcon sx={{ color: section === item ? 'rgb(210, 0, 3)': 'rgb(194, 0, 3)', fontSize: section === item ? 23 : 20 }} />}

            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};


export default CategoriesList;
