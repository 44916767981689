import React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';

// Components
import Header from './list/Header';
import PatientsList from '../../../general/doctor/patients/PatientsList';

type Props = {
    children?: React.ReactNode,
    isPortraitMode: boolean,
};

const PatientsColumn: React.FC<Props> = ({ children, isPortraitMode }) => {

  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '100%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>
        <Header />
        <PatientsList />
    </Box>
  );
};

export default PatientsColumn;