import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Routes 

import { getHackers } from '../../../../routes/hacker/users';

// Components 

import HackerRow from './HackerRow';
import ListHeader from './ListHeader';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const HackersList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const [hackers, setHackers] = useState(Array<any>);

    // -- 

    const getHackersList = async () => { 

        const res = await getHackers() as any; 

        if (res.data.status === '200') { 

            setHackers(res.data.hackers); 

        }; 

    }; 

    // --

    useEffect(() => { 

        getHackersList(); 

    },[]); 

    // -- 

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '90vw',
              backgroundColor: '#383A40',
            }}>            
            
                <ListHeader />

                { hackers?.length >= 1 && 
                <Box sx={{ 
                    overflow: 'auto',
                    width: '100%',
                    marginTop: '2vh' }}>
            
                    <List dense>
            
                    {hackers?.map((item, index) => (
            
                        <ListItem button key={index}>

                            <HackerRow 
                                firstName={item.firstName}
                                lastName={item.lastName}
                                email={item.email}
                                main={item.main}
                                createdAt={moment.utc(item.createdAt).format('LL')}
                            />  
        
                        </ListItem>
                    ))}
            
                    </List>
            
                </Box>}

                {hackers?.length === 0 &&         
                
                    <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    height: '100%',
                    width: '100%',
                    color: '#C2C5C8'
                    }}>

                    <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

                    <div>No hacker account detected.</div>

                </Box>}
    
            </Box>

        </Box>
    
      );

};

export default HackersList;