import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import DefaultFolders from './folders/DefaultFolders';
import FoldersList from './folders/FoldersList';

const NavigationColumn: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '15%',
            height: '100%', 
            fontSize: 12, 
            color: '#C2C5C8'
        }}>

            <DefaultFolders />

            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%', 
                    marginLeft: 1,
                    marginTop: 15
                }}
            >{t('myFolders')}</Box>

            <FoldersList /> 
            
        </Box>

    );

};

export default NavigationColumn;