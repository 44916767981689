
import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// ---

// ---

// DEPRECATED !!!

// If you want to add a file-related route, add it in routes/doctor/files.ts 

// ---

// ---

// --  

export const getDoctorRecentFiles = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/doctor`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

// --  

export const getDoctorPatients = async (doctorId: string) => {

    try {

        const res = await axios.get(`/doctor/dashboard/documents/files/transferFiles/doctor/patients/${doctorId}`, getConfig());

        if (res.status === 200) { 

            const patients = res.data.patients as Array<object>; 

            return patients; 

        };

    } catch (error) {
        console.error('Error getting full name:', error);
    };

};



export const getPatientFullName = async (patientId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/dashboard/documents/files/transferFiles/patient/name/${patientId}`, getConfig());

        if (res.status === 200) { 
            return res.data.fullName; 

        };

    } catch (error) {
        console.error('Error getting full name:', error);
    };

};

// -- 

export const getPatientFiles = async (patientId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/dashboard/documents/files/transferFiles/patient/files/${patientId}`, getConfig());

        if (res.status === 200) { 

            const patientFiles = res.data.patientFiles as Array<object>; 

            return patientFiles; 

        };

    } catch (error) {
        console.error('Error getting patient files:', error);
    };

};

// -- 

export const uploadFile = async (formData: any) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/upload`, formData, getConfig());

        return res; 

    } catch (error) {
        console.error('Error sending uploading a file:', error);
    };

};

// -- 

export const getBlobFile = async (fileId: any) => {

    try {

        const content = { 
            fileId: fileId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/serve`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error sending uploading a file:', error);
    };

};
