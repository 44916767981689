import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setCurrentMedications } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const HealthInfo: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const currentMedications = useAppSelector((state) => state.createPatient.currentMedications); 

    const [currentMedicationsError, setCurrentMedicationsError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'currentMedications') { 

            dispatch(setCurrentMedications(input)); 

            dispatch(removeErroredField('currentMedications'));

        };

    };

    // -- 


    useEffect(() => { 

        if (currentMedications) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (currentMedications) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[currentMedications]); 

    // -- 

    useEffect(() => { 

        erroredFields?.includes('currentMedications') ? setCurrentMedicationsError(true) : setCurrentMedicationsError(false);

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('medicationName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={currentMedications}
                onChange={(e) => handleInput(e.target.value, 'currentMedications')}
                fullWidth
                error={currentMedicationsError}
                variant="outlined"
            />

        </Box>
    );
};

export default HealthInfo;