import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetFileExplorer, selectFile } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

type Props = {
    children?: React.ReactNode;
};


const ConfirmButton: React.FC<Props> = ({ children }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const unconfirmedSelection = useAppSelector((state) => state.fileExplorer.unconfirmedSelection); 

    // -- 

    const confirmSelection = () => { 

        dispatch(selectFile(unconfirmedSelection)); 

    }; 

    // -- 

    const closeFileExplorer = () => { 

        dispatch(resetFileExplorer()); 

    }; 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            marginRight: '1%',
            marginBottom: '1%', 
            bottom: 0, 
            right: 0, 
            position: 'absolute'
        }}>

        { unconfirmedSelection ? 
        <Button onClick={() => confirmSelection()} variant="contained">{t('confirm')}</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">{t('confirm')}</Button>}

        <Button 
            onClick={() => closeFileExplorer()} 
            sx={{ 
                marginLeft: 1,
                backgroundColor: 'transparent',
                borderColor: 'lightblue',
                '&:hover': 
                { 
                    backgroundColor: 'rgb(147, 147, 147)',
                    color: 'rgb(207, 207, 207)',
                    borderColor: 'rgb(147, 147, 147)'
                }
            }}
            variant="contained">{t('cancel')}</Button>
            
        </Box>

    );

};

export default ConfirmButton;