import { CompressOutlined } from '@mui/icons-material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreatePatientState {

    // Meta 

    categories: string[],
    section: string,
    completedCategories: string[],
    incompletedCategories: string[], 
    erroredCategories: string[], 
    erroredFields: string[], 
    nextSection: string,
    previousSection: string,
    sectionSelected : boolean

    // Patient Info

    firstName: string,
    lastName: string,
    gender: string,
    age: string,
    birthDate: string,

    // Contact Info 

    healthCardNumber: string,
    medicalHistory: string,
    phone: string,
    email: string, 
    address: string, 

    // Emergency Contact 

    emergencyContactName: string, 
    emergencyContactRelation: string, 
    emergencyContactPhone: string, 

    // Health Information 

    currentMedications: string,     // Previously an array 

    // Medical History 

    familyMedicalHistory: string,  // Previously an array 
    mentalHealthHistory: string,  // Previously an array 
    allergies: string,  // Previously an array 
    bloodType: string, 
    knownConditions: string, // Previously an array 
    surgeries: string, // Previously an array 
    immunizations: string, // Previously an array 
    geneticDisorders: string, // Previously an array 
    disabilities: string, // Previously an array 

    // Insurance Details 

    provider: string,
    policyNumber: string,
    groupNumber: string, 

    // Social History 

    occupation: string,
    maritalStatus: string,
    housingStatus: string,
    financialStatus: string,
    supportSystem: string,

    // Lifestyle 

    smokingStatus: string,
    alcoholConsumption: string,
    exerciseFrequency: string,
    diet: string,

    // Extra Information 

    languagePreference: string;
    primaryDoctor: string;
    advancedDirectives: string;   // Previously an array 
    notes: string;

};

// ---------------

const initialState: CreatePatientState = {

    // Meta 

    categories: [],
    section: '',
    completedCategories: [],
    incompletedCategories: [], 
    erroredCategories: [], 
    erroredFields: [], 
    previousSection: '',
    nextSection: '',
    sectionSelected : false,

    // Patient Info

    firstName: '', 
    lastName: '',
    gender: '', 
    age: '',
    birthDate: '',

    // Contact Info

    healthCardNumber: '', 
    medicalHistory: '', 
    phone: '', 
    email: '', 
    address: '', 

    // Emergency Contact

    emergencyContactName: '', 
    emergencyContactRelation: '', 
    emergencyContactPhone: '', 

    // Health Information 

    currentMedications: '', // Previously an array 

    // Medical History 

    familyMedicalHistory: '',  // Previously an array 
    mentalHealthHistory: '',  // Previously an array 
    allergies: '',  // Previously an array 
    bloodType: '', 
    knownConditions: '', // Previously an array 
    surgeries: '', // Previously an array 
    immunizations: '', // Previously an array 
    geneticDisorders: '', // Previously an array 
    disabilities: '', // Previously an array 

    // Insurance Details 

    provider: '',
    policyNumber: '',
    groupNumber: '',

    // Social History 

    occupation: '',
    maritalStatus: '',
    housingStatus: '',
    financialStatus: '',
    supportSystem: '',

    // Lifestyle

    smokingStatus: '',
    alcoholConsumption: '',
    exerciseFrequency: '',
    diet: '',

    // Extra Information 

    languagePreference: '',
    primaryDoctor: '',
    advancedDirectives: '',  // Previously an array 
    notes: '',
    
};

// ---------------

export const createPatientSlice = createSlice({

  name: 'createPatient',

  initialState,

  reducers: {

    setSectionSelected : (state, action: PayloadAction<any>) => {

        state.sectionSelected = action.payload; 

    },

    setCategories: (state, action: PayloadAction<any>) => {

        state.categories = action.payload; 
        state.section = action.payload[0]; 
        state.nextSection = action.payload[1]; 

    },
    setSection: (state, action: PayloadAction<any>) => {

        state.section = action.payload; 

        const currentSectionIndex = state.categories.indexOf(action.payload); 

        if (currentSectionIndex === 0) { 

            state.previousSection = ''; 
            state.nextSection = state.categories[1]; 

        } else if (currentSectionIndex === (state.categories.length - 1)) { 

            state.previousSection = state.categories[currentSectionIndex -1]; 
            state.nextSection = 'confirmation'; 
        
        } else { 

            if (action.payload === 'confirmation') { 

                state.previousSection = state.categories[state.categories.length - 1]; 
                state.nextSection = ''; 

            } else { 

                state.previousSection = state.categories[currentSectionIndex -1]; 
                state.nextSection = state.categories[currentSectionIndex + 1]; 

            }; 

        }; 

    },
    setCompletedCategory: (state, action: PayloadAction<any>) => {

        !state.completedCategories.includes(action.payload) && state.completedCategories.push(action.payload); 

    },
    removeCompletedCategory: (state, action: PayloadAction<string>) => {

        const currentCompletedCategories = state.completedCategories; 

        const categoryToBeRemoved = currentCompletedCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentCompletedCategories.splice(categoryToBeRemoved, 1); 

            state.completedCategories = currentCompletedCategories;

        };

    },
    setIncompletedCategory: (state, action: PayloadAction<any>) => {

        !state.incompletedCategories.includes(action.payload) && state.incompletedCategories.push(action.payload); 

    },
    removeIncompletedCategory: (state, action: PayloadAction<string>) => {

        const currentIncompletedCategories = state.incompletedCategories; 

        const categoryToBeRemoved = currentIncompletedCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentIncompletedCategories.splice(categoryToBeRemoved, 1); 

            state.incompletedCategories = currentIncompletedCategories; 

        };

    },
    setErroredCategory: (state, action: PayloadAction<any>) => {

        !state.erroredCategories.includes(action.payload) && state.erroredCategories.push(action.payload); 

    },
    removeErroredCategory: (state, action: PayloadAction<string>) => {

        const currentErroredCategories = state.erroredCategories; 

        const categoryToBeRemoved = currentErroredCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentErroredCategories.splice(categoryToBeRemoved, 1); 

            state.erroredCategories = currentErroredCategories; 

        };

    },
    setErroredField: (state, action: PayloadAction<string>) => {

        !state.erroredFields.includes(action.payload) && state.erroredFields.push(action.payload); 

    }, 
    removeErroredField: (state, action: PayloadAction<string>) => {

        const currentErroredFields = state.erroredFields; 

        const fieldToBeRemoved = currentErroredFields.indexOf(action.payload); 

        if (fieldToBeRemoved > -1) { 

            currentErroredFields.splice(fieldToBeRemoved, 1); 

            state.erroredFields = currentErroredFields; 

        };

    },
    setFirstName: (state, action: PayloadAction<any>) => {

        state.firstName = action.payload; 

    },
    setLastName: (state, action: PayloadAction<any>) => {

        state.lastName = action.payload; 

    },
    setGender: (state, action: PayloadAction<any>) => {

        state.gender = action.payload; 

    },
    setAge: (state, action: PayloadAction<any>) => {

        state.age = action.payload; 

    },
    setBirthDate: (state, action: PayloadAction<any>) => {

        state.birthDate = action.payload; 

    },
    setHealthCardNumber: (state, action: PayloadAction<any>) => {

        state.healthCardNumber = action.payload; 

    },
    setMedicalHistory: (state, action: PayloadAction<any>) => {

        state.medicalHistory = action.payload; 

    },
    setPhone: (state, action: PayloadAction<any>) => {

        state.phone = action.payload; 

    },
    setEmail: (state, action: PayloadAction<any>) => {

        state.email = action.payload; 

    },
    setAddress: (state, action: PayloadAction<any>) => {

        state.address = action.payload; 

    },
    setEmergencyContactName: (state, action: PayloadAction<any>) => {

        state.emergencyContactName = action.payload; 

    },
    setEmergencyContactRelation: (state, action: PayloadAction<any>) => {

        state.emergencyContactRelation = action.payload; 

    },
    setEmergencyContactPhone: (state, action: PayloadAction<any>) => {

        state.emergencyContactPhone = action.payload; 

    },
    setCurrentMedications: (state, action: PayloadAction<any>) => {

        state.currentMedications = action.payload; 

    }, 
    setFamilyMedicalHistory: (state, action: PayloadAction<any>) => {

        state.familyMedicalHistory = action.payload; 

    }, 
    setMentalHealthHistory: (state, action: PayloadAction<any>) => {

        state.mentalHealthHistory = action.payload; 

    }, 
    setAllergies: (state, action: PayloadAction<any>) => {

        state.allergies = action.payload; 

    }, 
    setBloodType: (state, action: PayloadAction<any>) => {

        state.bloodType = action.payload; 

    }, 
    setKnownConditions: (state, action: PayloadAction<any>) => {

        state.knownConditions = action.payload; 

    }, 
    setSurgeries: (state, action: PayloadAction<any>) => {

        state.surgeries = action.payload; 

    }, 
    setImmunizations: (state, action: PayloadAction<any>) => {

        state.immunizations = action.payload; 

    }, 
    setGeneticDisorders: (state, action: PayloadAction<any>) => {

        state.geneticDisorders = action.payload; 

    }, 
    setDisabilities: (state, action: PayloadAction<any>) => {

        state.disabilities = action.payload; 

    }, 
    setProvider: (state, action: PayloadAction<any>) => {

        state.provider = action.payload; 

    },
    setPolicyNumber: (state, action: PayloadAction<any>) => {

        state.policyNumber = action.payload; 

    },
    setGroupNumber: (state, action: PayloadAction<any>) => {

        state.groupNumber = action.payload; 

    },
    setOccupation: (state, action: PayloadAction<any>) => {

        state.occupation = action.payload; 

    },
    setMaritalStatus: (state, action: PayloadAction<any>) => {

        state.maritalStatus = action.payload; 

    },
    setHousingStatus: (state, action: PayloadAction<any>) => {

        state.housingStatus = action.payload; 

    },
    setFinancialStatus: (state, action: PayloadAction<any>) => {

        state.financialStatus = action.payload; 

    },
    setSupportSystem: (state, action: PayloadAction<any>) => {

        state.supportSystem = action.payload; 

    },
    setSmokingStatus: (state, action: PayloadAction<any>) => {

        state.smokingStatus = action.payload; 

    },
    setAlcoholConsumption: (state, action: PayloadAction<any>) => {

        state.alcoholConsumption = action.payload; 

    },
    setExerciseFrequency: (state, action: PayloadAction<any>) => {

        state.exerciseFrequency = action.payload; 

    },
    setDiet: (state, action: PayloadAction<any>) => {

        state.diet = action.payload; 

    },
    setLanguagePreference: (state, action: PayloadAction<any>) => {

        state.languagePreference = action.payload; 

    },
    setPrimaryDoctor: (state, action: PayloadAction<any>) => {

        state.primaryDoctor = action.payload; 

    },
    setAdvancedDirectives: (state, action: PayloadAction<any>) => {

        state.advancedDirectives = action.payload; 

    },
    setNotes: (state, action: PayloadAction<any>) => {

        state.notes = action.payload; 

    },
    setResetCreatePatient: (state) => { 
        
        state.categories = []; 
        state.section = ''; 
        state.completedCategories = [];     
        state.incompletedCategories = []; 
        state.erroredCategories = [];     
        state.erroredFields = [];
        sectionSelected : false;

        state.firstName = ''; 
        state.lastName = ''; 
        state.gender = ''; 
        state.age = ''; 
        state.birthDate = ''; 

        state.healthCardNumber = ''; 
        state.medicalHistory = '';
        state.phone = '';
        state.email = ''; 
        state.address = '';

        state.emergencyContactName = '';
        state.emergencyContactRelation = ''; 
        state.emergencyContactPhone = '';

        state.currentMedications = ''; // Previously an array 

        state.familyMedicalHistory = ''; // Previously an array 
        state.mentalHealthHistory = '';  // Previously an array 
        state.allergies = ''; // Previously an array 
        state.bloodType = ''; 
        state.knownConditions = ''; // Previously an array 
        state.surgeries = ''; // Previously an array 
        state.immunizations = ''; // Previously an array 
        state.geneticDisorders = ''; // Previously an array 
        state.disabilities = ''; // Previously an array 

        state.provider = ''; 
        state.policyNumber = ''; 
        state.groupNumber = ''; 

        state.occupation = ''; 
        state.maritalStatus = ''; 
        state.housingStatus = ''; 
        state.financialStatus = ''; 
        state.supportSystem = ''; 


        state.smokingStatus = ''; 
        state.alcoholConsumption = ''; 
        state.exerciseFrequency = ''; 
        state.diet = ''; 

        state.languagePreference = ''; 
        state.primaryDoctor = ''; 
        state.advancedDirectives = '';   // Previously an array 
        state.notes = ''; 

    } 
  },

}); 

// ---------------

export const { setSectionSelected, setCategories, setSection, setCompletedCategory, setIncompletedCategory, 
    setErroredCategory, removeCompletedCategory, setErroredField, 
    removeIncompletedCategory, removeErroredCategory, removeErroredField, 
    setFirstName,  setLastName, setAge, setBirthDate, 
    setGender, setResetCreatePatient, setHealthCardNumber, 
setMedicalHistory, setPhone, setEmail, setAddress,
setEmergencyContactName, setEmergencyContactRelation, setEmergencyContactPhone,
setCurrentMedications, setFamilyMedicalHistory, setMentalHealthHistory,
setAllergies, setBloodType, setKnownConditions, setSurgeries, setImmunizations,
setGeneticDisorders, setDisabilities, setProvider, setPolicyNumber, 
setGroupNumber, setOccupation, setMaritalStatus, setHousingStatus, 
setFinancialStatus, setSupportSystem, setSmokingStatus, setAlcoholConsumption, 
setExerciseFrequency, setDiet, setLanguagePreference, setPrimaryDoctor,
setAdvancedDirectives, setNotes  } = createPatientSlice.actions;

export default createPatientSlice.reducer;