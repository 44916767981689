import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';
import { setPatientSection, setSelectedPatient } from './../../../../redux/features/doctor/Dashboard/patients/patient';
import { setPatientList } from './../../../../redux/features/doctor/Dashboard/patients/patientList';
import { getPatientsList } from './../../../../routes/doctor/dashboard';
import { getPatientInfo } from './../../../../routes/doctor/dashboard';
import PatientsListRow from './PatientsListRow';

import GroupsIcon from '@mui/icons-material/Groups';

interface Patient {
  firstName: string;
  lastName: string;
  updatedAt: Date;
}

type Props = {
  children?: React.ReactNode;
};

const PatientsList: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const patients = useAppSelector((state) => state.patientList.patients);
  const [patientNames, setPatientNames] = useState({});
  const [patientsList, setPatientsList] = useState(Array<any>);
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);

  useEffect(() => { 

    getCurrentPatientsList();
  
  },[]);

  useEffect(() => { 

    setPatientsList(patients);
  
  },[patients]);

  const getCurrentPatientsList = async () => {
    try {

      const data = await getPatientsList() as Array<any>;
  

      const patientsInfoPromises = data.map(async (item) => {
        const patientInfo = await getPatientInfo(item.patientId);
        return patientInfo;
      });

      const patientsInfo = await Promise.all(patientsInfoPromises);
  

      dispatch(setPatientList(patientsInfo));
    } catch (error) {
      console.error("Error fetching patient data", error);
    }
  };

  const selectPatient = (item: any) => {
    setSelectedPatientId(item);
    dispatch(setSelectedPatient(item));
    dispatch(setPatientSection('form')); 
  };

  return (
    <Box
      sx={{
        display: patientsList == null ? 'flex' : 'block', // Flex when no patients, block otherwise
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden',
        width: '95%',
        marginTop: '1vh',
        height: '100vh', // Ensure the Box takes full height to center vertically
      }}
    >
      {patientsList?.length > 0 && 
        <List dense>
          {patientsList.map((item, index) => (
            <ListItem
              sx={{
                cursor: 'pointer',
                backgroundColor: selectedPatientId === item._id ? '#4D4B59' : 'transparent',
                '&:hover': { backgroundColor: '#4D4B59' },
              }}
              onClick={() => selectPatient(item._id)}
              key={index}
            >
              <PatientsListRow 
                patientId={item._id}
                selectedPatientId={selectedPatientId}
              />
            </ListItem>
          ))}
        </List>}

        { patientsList?.length === 0 && 
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%', 
          }}
        >
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <GroupsIcon sx={{ color: '#C2C5C8', marginBottom: 3, fontSize: 50 }}/>
            <Box sx={{ color: '#C2C5C8', fontSize: 12 }}>{t('noPatients')}</Box>
          </Box>
        </Box>}


    </Box>
  );
  
};


export default PatientsList;