import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { resetFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Icons 

import CloseIcon from '@mui/icons-material/Close';

type Props = {
    children?: React.ReactNode;
};


const ExitButton: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const handleClosure = () => { 

        dispatch(resetFileExplorer()); 

    };

    return (

        <Box 
            onClick={handleClosure}
            sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            position: 'absolute',
            top: 0, 
            right: 0,
            color: '#C2C5C8',
            paddingLeft: 1,
            paddingRight: 1,
            paddingTop: 0.4,
            paddingBottom: 0.4,
            borderTopLeftRadius: 2,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'rgb(207, 53, 14)', 
                color: 'white',
            },
        }}>

            <CloseIcon />

        </Box>

    );

};

export default ExitButton;