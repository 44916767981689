import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

// Components 

import Box from '@mui/material/Box';

import PatientInfo from './categories/PatientInfo';
import ContactInfo from './categories/ContactInfo';
import EmergencyContact from './categories/EmergencyContact';
import HealthInfo from './categories/HealthInfo';
import MedicalHistory from './categories/MedicalHistory';
import InsuranceDetails from './categories/InsuranceDetails';
import SocialHistory from './categories/SocialHistory';
import Lifestyle from './categories/Lifestyle';
import ExtraInformation from './categories/ExtraInformation';

import ConfirmationTitle from './ConfirmationTitle';

const Confirmation: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
        }}>

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                color: 'rgb(150, 150, 150)',
                fontSize: 15,
                marginBottom: 3,
            }}>{t('confirmPatientInfo')}</Box>

            <ConfirmationTitle title={'patientInfo'} />

            <PatientInfo /> 

            <ConfirmationTitle title={'contactInfo'} />

            <ContactInfo /> 

            <ConfirmationTitle title={'emergencyContact'} />

            <EmergencyContact />

            <ConfirmationTitle title={'healthInformation'} />

            <HealthInfo /> 

            <ConfirmationTitle title={'medicalHistory'} />

            <MedicalHistory />

            <ConfirmationTitle title={'insuranceDetails'} />

            <InsuranceDetails />

            <ConfirmationTitle title={'socialHistory'} />

            <SocialHistory />

            <ConfirmationTitle title={'lifestyle'} />

            <Lifestyle /> 

            <ConfirmationTitle title={'extraInformation'} />

            <ExtraInformation /> 

        </Box>
    );
};

export default Confirmation;