import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setProvider, setPolicyNumber, setGroupNumber } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const InsuranceDetails: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const provider = useAppSelector((state) => state.createPatient.provider); 
    const policyNumber = useAppSelector((state) => state.createPatient.policyNumber); 
    const groupNumber = useAppSelector((state) => state.createPatient.groupNumber); 

    const [providerError, setProviderError] = useState(false); 
    const [policyNumberError, setPolicyNumberError] = useState(false); 
    const [groupNumberError, setGroupNumberError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'provider') { 

            dispatch(setProvider(input)); 

            dispatch(removeErroredField('provider'));

        } else if (section === 'policyNumber') { 

            dispatch(setPolicyNumber(input)); 

            dispatch(removeErroredField('policyNumber'));

        } else if (section === 'groupNumber') { 

            dispatch(setGroupNumber(input)); 

            dispatch(removeErroredField('groupNumber'));

        };

    };

    // -- 

    useEffect(() => { 

        if (provider && policyNumber && groupNumber) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (provider || policyNumber || groupNumber) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[provider, policyNumber, groupNumber]); 


    // -- 

    useEffect(() => { 

        erroredFields?.includes('provider') ? setProviderError(true) : setProviderError(false);
        erroredFields?.includes('policyNumber') ? setPolicyNumberError(true) : setPolicyNumberError(false);
        erroredFields?.includes('groupNumber') ? setGroupNumberError(true) : setGroupNumberError(false);

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('provider')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={provider}
                onChange={(e) => handleInput(e.target.value, 'provider')}
                fullWidth
                error={providerError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('policyNumber')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={policyNumber}
                onChange={(e) => handleInput(e.target.value, 'policyNumber')}
                fullWidth
                error={policyNumberError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('groupNumber')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={groupNumber}
                onChange={(e) => handleInput(e.target.value, 'groupNumber')}
                fullWidth
                error={groupNumberError}
                variant="outlined"
            />

        </Box>
    );
};

export default InsuranceDetails;