import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { resetFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

type Props = {
    children?: React.ReactNode;
  };


const EmptySpace: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const closeFileExplorer = () => { 

        dispatch(resetFileExplorer()); 

    }; 

    return (

    <div 
        style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100vw',
        height: '100vh',
        bottom: 0,
        right: 0,
        position: 'absolute', 
        zIndex: 0,
        backgroundColor: 'rgba(56, 58, 64, 0.5)'
    }}>
        
        <div 
            onClick={closeFileExplorer}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            left: 0,
        }}></div>

        <div 
            onClick={closeFileExplorer}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            top: 0,
        }}></div>

        <div 
            onClick={closeFileExplorer}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            right: 0,
        }}></div>

        <div 
            onClick={closeFileExplorer}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            bottom: 0,
        }}></div>


    </div>

    );

};

export default EmptySpace;