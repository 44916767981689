import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setOccupation, setMaritalStatus, setHousingStatus, setFinancialStatus, setSupportSystem } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const SocialHistory: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const occupation  = useAppSelector((state) => state.createPatient.occupation ); 
    const maritalStatus = useAppSelector((state) => state.createPatient.maritalStatus); 
    const housingStatus = useAppSelector((state) => state.createPatient.housingStatus); 
    const financialStatus = useAppSelector((state) => state.createPatient.financialStatus); 
    const supportSystem = useAppSelector((state) => state.createPatient.supportSystem); 

    const [occuptationError, setOccupationError] = useState(false); 
    const [maritalStatusError, setMaritalStatusError] = useState(false); 
    const [housingStatusError, setHousingStatusError] = useState(false);
    const [financialStatusError, setFinancialStatusError] = useState(false);  
    const [supportSystemError, setSupportSystemError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'occupation') { 

            dispatch(setOccupation(input)); 

            dispatch(removeErroredField('occupation'));

        } else if (section === 'maritalStatus') { 

            dispatch(setMaritalStatus(input)); 

            dispatch(removeErroredField('maritalStatus'));

        } else if (section === 'financialStatus') { 

            dispatch(setFinancialStatus(input)); 

            dispatch(removeErroredField('financialStatus'));

        } else if (section === 'housingStatus') { 

            dispatch(setHousingStatus(input)); 

            dispatch(removeErroredField('housingStatus'));

        } else if (section === 'supportSystem') { 

            dispatch(setSupportSystem(input)); 

            dispatch(removeErroredField('supportSystem'));

        }; 

    };

    // --

    useEffect(() => { 

        if (occupation && maritalStatus && housingStatus && financialStatus && supportSystem) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (occupation || maritalStatus || housingStatus || financialStatus || supportSystem) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[occupation, maritalStatus, housingStatus, financialStatus, supportSystem]); 

    // -- 

    useEffect(() => { 

        erroredFields?.includes('occupation') ? setOccupationError(true) : setOccupationError(false); 
        erroredFields?.includes('maritalStatus') ? setMaritalStatusError(true) : setMaritalStatusError(false);
        erroredFields?.includes('housingStatus') ? setHousingStatusError(true) : setHousingStatusError(false);
        erroredFields?.includes('financialStatus') ? setFinancialStatusError(true) : setFinancialStatusError(false);
        erroredFields?.includes('supportSystem') ? setSupportSystemError(true) : setSupportSystemError(false);

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('occupation')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={occupation}
                onChange={(e) => handleInput(e.target.value, 'occupation')}
                fullWidth
                error={occuptationError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('maritalStatus')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={maritalStatus}
                onChange={(e) => handleInput(e.target.value, 'maritalStatus')}
                fullWidth
                error={maritalStatusError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('housingStatus')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={housingStatus}
                onChange={(e) => handleInput(e.target.value, 'housingStatus')}
                fullWidth
                error={housingStatusError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('financialStatus')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={financialStatus}
                onChange={(e) => handleInput(e.target.value, 'financialStatus')}
                fullWidth
                error={financialStatusError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('supportSystem')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={supportSystem}
                onChange={(e) => handleInput(e.target.value, 'supportSystem')}
                fullWidth
                error={supportSystemError}
                variant="outlined"
            />

        </Box>
    );
};

export default SocialHistory;