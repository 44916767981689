import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components
import NavBar from '../../general/navBar/NavBar';
import Settings from '../navigation/navBar/Settings';
import Sidebar from '../../general/navigation/Sidebar';

import FileViewer from './dashboardActions/overview/overviewItems/FileViewer';
import FileExplorer from '../../general/doctor/files/FileExplorer';

// Redux
import { setSideBarMenu, setSubSideBarMenu } from '../../../redux/features/general/navigation';

// Navigation menus
import menuItems from '../../../util/navigation/sidebarMenu/doctor';
import doctorDashboardMenu from '../../../util/navigation/dashboard/doctor';

// Dashboard components
import DocumentInterface from './DocumentInterface';
import PatientsInterface from './PatientsInterface';
import InsurersInterface from './InsurersInterface';
import { useTranslation } from 'react-i18next';

const Container: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const section = useAppSelector((state) => state.navigation.section);
  const isFileViewerOpened = useAppSelector(state => state.overview.isFileViewerOpened); 
  const isFileExplorerOpened = useAppSelector((state) => state.fileExplorer.isFileExplorerOpened);


  useEffect(() => {
    dispatch(setSideBarMenu(menuItems));
    dispatch(setSubSideBarMenu(doctorDashboardMenu(t)));

  }, [t, i18n.language, dispatch]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          left: 0,
          zIndex: 0,
          overflowX: 'hidden',
          position: 'absolute',
          color: 'white',
        }}
      >
        <NavBar>
          {/* Items added to the navbar */}
          <Settings />
        </NavBar>

        <Sidebar />

        {/* SideBar sections */}
        {section === 'documents' && <DocumentInterface />}
        {section === 'patients' && <PatientsInterface />}
        {section === 'insurers' && <InsurersInterface />}
      </Box>

      { isFileViewerOpened && <FileViewer /> }
      {isFileExplorerOpened && <FileExplorer />}
    </React.Fragment>
  );
};

export default Container;