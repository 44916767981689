import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { getDoctors } from '../../../routes/hacker/users';

// Components 

import ListHeader from '../../hacker/doctors/ListHeader';
import DoctorRow from './DoctorRow';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const DoctorsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const refreshUsersList = useAppSelector(state => state.adminUserAccess.refreshUsersList); 

    const [doctors, setDoctors] = useState(Array<any>);

    const getDoctorsList = async () => { 

      const res = await getDoctors() as any; 

      if (res.data.status === '200') { 

        setDoctors(res.data.doctors); 

      };

    }; 

    useEffect(() => { 

        getDoctorsList(); 

    },[]); 
    

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '90vw',
              backgroundColor: '#383A40',
            }}>            
            
            <ListHeader />

            { doctors?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {doctors?.map((item, index) => (
        
                    <ListItem button key={index}>

                        <DoctorRow 
                          firstName={item.firstName}
                          lastName={item.lastName}
                          type={item.accountStatus}
                          email={item.email}
                          createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {doctors?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>No doctor account detected.</div>

          </Box>}
        
        

            </Box>

        </Box>
    
      );

};

export default DoctorsList;