const createPatientCategories = [
    'patientInfo',
    'contactInfo',
    'emergencyContact',
    'healthInformation',
    'medicalHistory',
    'insuranceDetails',
    'socialHistory',
    'lifestyle',
    'extraInformation',
];

export default createPatientCategories; 
