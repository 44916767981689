import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 

import { useAppSelector } from '../../../redux/hooks';

// Components

import PatientsColumn from './patients/PatientsColumn';
import ProgressColumn from './patients/createPatient/ProgressColumn';
import InfoContainer from './patients/information/InfoContainer';

const PatientsInterface: React.FC = () => {
  const theme = useTheme();

  const isPortraitMode = useMediaQuery('(orientation: portrait)');
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '6%' : '10%';

  const section = useAppSelector((state) => state.patient.section); 

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isPortraitMode ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: isPortraitMode ? 'flex-start' : 'space-evenly',
        width: '95%',
        height: '95%',
        left: leftOffset,
        paddingRight: "7%",
        bottom: 0,
        position: 'absolute',
        backgroundColor: '#201F1E',
        transition: 'left 0.3s, marginTop 0.3s',
        overflow: 'visible',
      }}
    >
      <Box 
        sx={{ 
          width: isPortraitMode ? '95%' : '23%',
          height: isPortraitMode ? '28%' : '95%', 
          borderRadius: 5,
          backgroundColor: '#313338',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginLeft: isPortraitMode ? '60px' : '0',
          marginTop: isPortraitMode ? '2%' : '0',
          marginBottom: isPortraitMode ? '2%' : '0',
          scrollbarWidth: 'none'
        }}
      >
        { (section === 'form' || section === 'file') && <PatientsColumn isPortraitMode={isPortraitMode} />}
        { section === 'createPatient' && <ProgressColumn isPortraitMode={isPortraitMode} />}
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: isPortraitMode ? '95%' : '70%',
          height: isPortraitMode ? '70%' : '95%',
          borderRadius: 5,
          backgroundColor: '#313338',
          marginLeft: isPortraitMode ? '60px' : '0',
        }}
      >
        <InfoContainer />
      </Box>
    </Box>
  );
};

export default PatientsInterface;
