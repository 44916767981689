import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppSelector } from './../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import HealthDataList from './healthData/HealthDataList';
import PersonOffTwoToneIcon from '@mui/icons-material/PersonOffTwoTone';
import emptyListImage from './../../../../../../assets/logos/empty_list.png'

interface HealthInfoProps {
  searchQuery: string;
}

const HealthInfo: React.FC<HealthInfoProps> = ({ searchQuery }) => {
  const { t } = useTranslation();

  const patient = useAppSelector(state => state.patient);
  const patients = useAppSelector((state) => state.patientList.patients);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (patient) {
      setTimeLeft(1);
    }
  }, [patient]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%', 
      borderRadius: 5,
      backgroundColor: '#313338',
    }}>
      { timeLeft ? <LinearProgress sx={{ marginTop: '2vh', borderRadius: 1, width: '95%'}} /> :
      <div style={{ marginTop: '2vh', borderRadius: 1, width: '95%' }}></div>}

      {patients === null && 
        <Box>
            <PersonOffTwoToneIcon />
            <em>{t('noPatients')}</em> 
        </Box>
      }
      
      {patient.selectedPatient === "" && 
        <Box
          sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
            height: '100%', 
          }}
        >
          <img src={emptyListImage} alt="No Patients Selected"  />
        </Box>
      }
      
      {patient.selectedPatient !== "" &&
        <HealthDataList searchQuery={searchQuery} />
      }
      
    </Box>
  );
};

export default HealthInfo;
