import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { setUnconfirmedSelection } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type Props = {
    children?: React.ReactNode;
};


const SelectedFileInfo: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const unconfirmedSelection = useAppSelector((state) => state.fileExplorer.unconfirmedSelection); 

    // -- 

    const removeSelectedFile = () => { 

        if (unconfirmedSelection) { 

            dispatch(setUnconfirmedSelection(null)); 

        }; 

    }; 

    // -- 

    return (

        <>

        { unconfirmedSelection && 
            
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                bottom: 0,
                marginBottom: '1%',
                position: 'absolute',
            }}>

                <HighlightOffIcon 
                    onClick={removeSelectedFile}
                    sx={{ 
                        cursor: 'pointer',
                        color: '#C2C5C8',
                        '&:hover': {
                            color: 'white',
                        },
                    }}

                />

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        borderStyle: 'solid',  
                        borderWidth: 1,
                        borderColor: '#C2C5C8',
                        padding: 1,
                        paddingLeft: 2,
                        paddingRight: 2,
                        borderRadius: 4,
                        marginLeft: 2
                    }}
                >

                    <InsertDriveFileIcon sx={{ color: 'rgb(255,185,0)', fontSize: 20 }} />

                    <Box sx={{ marginLeft: 2 }}>{unconfirmedSelection?.title}</Box>

                </Box>
                
                
            </Box>}

        </>

    );

};

export default SelectedFileInfo;