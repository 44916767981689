import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useTranslation } from 'react-i18next';

// Redux

import { useAppDispatch } from './../../../../../redux/hooks';

import { setPatientSection } from '../../../../../redux/features/doctor/Dashboard/patients/patient';

// Components 
import SortMenu from './SortMenu';

type Props = {
    children?: React.ReactNode;
};

const Header: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(setPatientSection('createPatient'));
  };

  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between', 
        width: '90%',
        height: '9vh',
        borderRadius: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
    }}>
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: '#0099cc',
        color: 'white',
        '&:hover': {
          backgroundColor: '#0077aa',
        },
        width: '75%',
      }}
    >
      + {t('addNewPatient')}
    </Button>
      <SortMenu />
      {children}
    </Box>
  );
};

export default Header;