import React from 'react';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

// Icon 

import SortIcon from '@mui/icons-material/Sort';

// Redux
import { useAppDispatch } from './../../../../../redux/hooks';
import { setListbyAscOrder, setListbyDescOrder, setListbyOldestOrder, setListbyLatestOrder } from './../../../../../redux/features/doctor/Dashboard/patients/patientList'; 


type Props = {
    children?: React.ReactNode
};

const SortMenu: React.FC<Props> = ({children}) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const handleAscOrder = () => {
        dispatch(setListbyAscOrder());
    };

    const handleDescOrder = () => {
        dispatch(setListbyDescOrder());
    };

    const handleOldestOrder = () => {
        dispatch(setListbyOldestOrder());
    };

    const handleLatestOrder = () => {
        dispatch(setListbyLatestOrder());
    };

    return (

        <div>

            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    backgroundColor: 'transparent',
                }}
            >
                <SortIcon
                    sx={{
                        cursor: 'pointer',
                        color: '#FFFFFF',
                        fontSize: 30,
                    }}
                />
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>{t('upcoming')}</MenuItem>
                <MenuItem onClick={handleAscOrder}>{t('nameAz')}</MenuItem>
                <MenuItem onClick={handleDescOrder}>{t('nameZa')}</MenuItem>
                <MenuItem onClick={handleLatestOrder}>{t('dateLo')}</MenuItem>
                <MenuItem onClick={handleOldestOrder}>{t('dateOl')}</MenuItem>

            </Menu>

        </div>

  );

};

export default SortMenu;

