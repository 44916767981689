import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// --

export const getRecentFiles = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/recent`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

// --

export const getDoctorRecentFiles = async () => {

    try {

        // For the file Explorer (compared to getRecentFiles(), which only render files for the Overview section). 

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/doctor`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

// -- 

export const getDoctorRootFolders = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/folders`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor root folders:', error);
    }
};

// --

export const getFolderData = async (folderId: string) => {

    try {

        const content = { 
            folderId: folderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/data`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};

// -- 

export const getFolderDataByPath = async (path: string) => {

    try {

        const content = { 
            path: path
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/path/data`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};


// -- 

export const createFolder = async (newFolder: any) => {

    try {

        const content = { 
            title: newFolder.title, 
            folder: newFolder.folder,
            description: newFolder.description, 
            relatedPatient: newFolder.relatedPatient
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folders/create`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a new folder:', error);
    }
};

// --

export const searchFileExplorer = async (currentFolder: any, searchQuery: string) => {

    try {

        const content = { 
            currentFolder: currentFolder, 
            searchQuery: searchQuery
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/search`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};

// -- 

export const getSuggestedCorrections = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ai/suggest-corrections`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor root documents:', error);
    }; 

}; 

// -- 

export const serveFile = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId, 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/serve`, content, getBlobHeadersConfig());

        return res; 

        // let fileBlob, fileUrl;
        // const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
        // const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';
  
        // if (fileExtension === 'pdf') {
        //   fileBlob = new Blob([response.data], { type: 'application/pdf' });
        //   fileUrl = URL.createObjectURL(fileBlob);
        // } else {
        //   fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
        //   fileUrl = URL.createObjectURL(fileBlob);
        // }
  
        // onSuccess(fileUrl, fileExtension, fileName);

        // dispatch(setNotificationMessage(t('serveFileNotOK')));
        
    } catch (error) {
        console.error('Error serving a file.', error);
    }; 

}; 

// -- 

export const updateFile = async (fileId: string, title: string, description: string, folder: string) => { 

    try {

        const content = { 
            fileId: fileId, 
            title: title, 
            description: description, 
            folder: folder
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/update`, content, getConfig());

        return res; 

        // dispatch(setNotificationMessage(t('fileUpdatedOK')));
        // dispatch(setNotificationMessage(t('fileUpdatedNotOK')));

        // Conditions (error handling); 

        // dispatch(setNotificationMessage(t('fileTitleLimit50Char')));
        // dispatch(setNotificationMessage(t('fileTitleLimit280Char')));

        
    } catch (error) {
        console.error('Error updating a file.', error);
    }; 

}; 

// -- 

export const deleteFile = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId, // data: fileId for some reason. To be modified. 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/delete`, content, getConfig());

        return res; 

        //  dispatch(setNotificationMessage(t('fileDeleteOK')));
        //  dispatch(setNotificationMessage(t('fileDeleteNotOK')));

        
    } catch (error) {
        console.error('Error deleting a file.', error);
    }; 

}; 

// -- 

export const getRootFolder = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/folder/root`, getConfig());

        return res;
        
    } catch (error) {
        console.error('Error retrieving the documents folder.', error);
    }; 

}; 
