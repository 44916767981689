import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// // Routes 

// import { getDoctorRecentFiles } from './../../../../../../routes/doctor/transferFiles';
// import { setDoctorRecentFiles } from './../../../../../../redux/features/doctor/Dashboard/documents/correctFile';

// // Components 

import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import SectionHeader from '../../../../../general/doctor/files/SectionHeader';
// import DocumentSelection from './formItems/DocumentSelection';
// import SearchList from '../../../../../general/doctor/files/SearchList';
// import Selection from './formItems/Selection';
// import SubmitButton from './formItems/SubmitButton';

import SectionHeader from '../../../../../../general/doctor/files/SectionHeader';


const Corrections: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.meta);

    const selectedFile = useAppSelector(state => state.correctFile.selectedFile);
    const corrections = useAppSelector(state => state.correctFile.corrections) as any; 

    // const correctionsArray = corrections?.split('\n\n').map((correction, index)

    const [mistake, correctionText] = corrections.split('\n') as any; 

    const isNoCorrection = !correctionText || correctionText.toLowerCase().includes('no corrections');
    const mistakeColor = isNoCorrection ? '#bbb' : '#f44336';
    const correctionColor = isNoCorrection ? '#bbb' : '#4caf50';
  
    return (

        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            }}
        >
  
        </Box> 


    );
}; 
  

export default Corrections;



        // {correctionsArray && 

        //     <Box
        // sx={{
        //     color: mistakeColor,
        //     fontWeight: 'bold',
        //     marginBottom: '0.5rem',
        // }}
        // >
        // {mistake || 'No mistakes detected'}
        // </Box>}
  
  
    // {correctionText && 
    //   <Box
    //     sx={{
    //       color: correctionColor,
    //       fontStyle: 'italic',
    //     }}
    //   >
    //     {correctionText}
    //   </Box>}





    //     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-evenly',
//         alignItems: 'center',
//         width: '95%',
//         height: '95%',
//         borderRadius: 5,
//         backgroundColor: '#383A40',
//       }}
//     >
//       {view === 'form' ? (
//         <div
//           style={{
//             overflow: 'hidden',
//             height: '90%',
//             width: '95%',
//             overflowY: 'hidden',
//             scrollbarWidth: 'none',
//           }}
//         >
//           <SectionHeader title={t('selectFile')} extraStyle={{ marginTop: 0 }} />
//           <DocumentSelection />
//           <SearchList />
//           {selectedFile && <SectionHeader title='Selection' />}
//           <Selection />
//           <SubmitButton onSuccess={handleFileResponse} />
//         </div>
//       ) : (



  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'column',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           width: '100%',
  //           height: '100%',
  //         }}
  //       >

  //         {renderFeedback()}

  //         <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
  //           <Button variant="contained" onClick={() => setView('form')} sx={{ mr: 2 }}>
  //             {t('goBack')}
  //           </Button>
  //         </Box>
  //       </div>
  //     )}
  //   </Box>
  // );'


  //   const { t } = useTranslation();
//   const dispatch = useAppDispatch();
//   const account = useAppSelector(state => state.meta);

//   const selectedFile = useAppSelector(state => state.correctFile.selectedFile);

//   const getDoctorFiles = async () => {

//     const res = await getDoctorRecentFiles();
//     dispatch(setDoctorRecentFiles(res?.data.files));
    
//   };

//   useEffect(() => {
//     if (account) {
//       getDoctorFiles();
//     }
//   }, [account]);

//   const [view, setView] = useState<'form' | 'feedback'>('form');
//   const [feedback, setFeedback] = useState<string | null>(null);

//   const handleFileResponse = (aiFeedback: { corrections: string }) => {
//     const { corrections } = aiFeedback;
//     setFeedback(corrections);
//     setView('feedback');
//   };




//   const renderFeedback = () => {
//     if (!feedback) return null;
  
//     const correctionsArray = feedback.split('\n\n').map((correction, index) => {

//       const [mistake, correctionText] = correction.split('\n');
  
//       const isNoCorrection = !correctionText || correctionText.toLowerCase().includes('no corrections');
//       const mistakeColor = isNoCorrection ? '#bbb' : '#f44336';
//       const correctionColor = isNoCorrection ? '#bbb' : '#4caf50';
  
//       return (
//         <Box
//           key={index}
//           sx={{
//             backgroundColor: '#2c2f33',
//             padding: '1.5rem',
//             borderRadius: '8px',
//             marginBottom: '1.5rem',
//             boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
//           }}
//         >
//           {/* Mistake */}
//           <Box
//             sx={{
//               color: mistakeColor,
//               fontWeight: 'bold',
//               marginBottom: '0.5rem',
//             }}
//           >
//             {mistake || 'No mistakes detected'}
//           </Box>
  
//           {/* Correction */}
//           {correctionText && (
//             <Box
//               sx={{
//                 color: correctionColor,
//                 fontStyle: 'italic',
//               }}
//             >
//               {correctionText}
//             </Box>
//           )}
//         </Box>
//       );
//     });
  
//     return (
//       <Box
//         sx={{
//           padding: '2rem',
//           width: '100%',
//           height: '90vh',
//           backgroundColor: '#202225',
//           color: '#fff',
//           overflow: 'auto',
//           borderRadius: '8px',
//         }}
//       >
//         <SectionHeader title={t('aiFeedbackTitle')} />
//         {correctionsArray}
//       </Box>
//     );

//   }; 
    



