import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '@mui/material/Button';

// Routes

import { createHacker } from '../../../../routes/hacker/users';

// Redux 

import { resetCreateHacker } from '../../../../redux/features/hacker/users/createHacker';
import { setNotificationMessage } from '../../../../redux/features/general/notification';


const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const firstName = useAppSelector(state => state.createHacker.firstName); 
    const lastName = useAppSelector(state => state.createHacker.lastName); 
    const email = useAppSelector(state => state.createHacker.email); 
    const main = useAppSelector(state => state.createHacker.main); 

  const [allowUserCreation, setAllowUserCreation] = useState(false); 

  useEffect(() => { 

    if (firstName && lastName && email) { 

        setAllowUserCreation(true); 

    } else { 

        setAllowUserCreation(false); 

    };

  },[firstName, lastName, email]); 

  // -- 

  const handleCreateDoctor = async () => { 

     const res = await createHacker(email, firstName, lastName, main) as any; 

     if (res.data.status === '200') { 

      // Redux is reset. 

      dispatch(resetCreateHacker()); 

      dispatch(setNotificationMessage('Hacker Successfully Created')); 

     } else if (res.data.status === '401') { 

        if (res.data.category === 'email') { 

          dispatch(setNotificationMessage('Invalid Email.')); 

        }; 

    }; 

  }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '5vh',
        height: '5vh',
        marginBottom: '3vh',
    }}>

        { allowUserCreation ? 
        <Button onClick={() => handleCreateDoctor()} variant="contained">Create Hacker</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">Create Hacker</Button>}


    </Box>

  );

};

export default SubmitButton;