import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 
import Menu from './dashboardActions/menu/Menu';

// Redux 

import { openFileExplorer } from '../../../redux/features/doctor/Dashboard/documents/fileExplorer';

import Overview from './dashboardActions/overview/Overview';
import Templates from './dashboardActions/templates/Templates';
import InsertFile from './dashboardActions/files/insertFile/InsertFile';
import TransferFile from './dashboardActions/files/transfertFile/TransferFile';
import CorrectFile from './dashboardActions/files/correctFile/CorrectFile';

const DocumentInterface: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const subSection = useAppSelector(state => state.navigation.subSection);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '10%';

  useEffect(() => { 

    if (subSection === 'fileExplorer') { 

      dispatch(openFileExplorer());  

    }; 

  },[subSection]); 

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '90%',
      height: '95%', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: '#201F1E',
      transition: 'left 0.3s',
    }}>
      <Menu />
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '70%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
      }}>
        { subSection === 'overview' && <Overview />}
        { subSection === 'selectTemplate' && <Templates />}
        { subSection === 'uploadFile' && <InsertFile />}
        { subSection === 'transferFile' && <TransferFile />}
        { subSection === 'correctFile' && <CorrectFile />}
      </Box>
    </Box>
  );
};

export default DocumentInterface;