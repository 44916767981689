import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface UserAccessState {
  usersListLength: number,
  selectedUser: any,
  refreshUsersList: boolean,
}

// ---------------

const initialState: UserAccessState = {
    usersListLength: 0, 
    selectedUser: null,
    refreshUsersList: false, 
};

// ---------------


export const userAccessSlice = createSlice({

  name: 'userAccess',

  initialState,

  reducers: {

    setUsersListLength: (state, action: PayloadAction<number>) => {

        state.usersListLength = action.payload; 
    },
    setSelectedUser: (state, action: PayloadAction<any>) => { 
        
        state.selectedUser = action.payload;  
    },
    setRefreshUsersList: (state, action: PayloadAction<boolean>) => { 

        state.refreshUsersList = action.payload; 

    },
    setResetUserAccess: (state) => { 
        
        state = initialState; 

    } 

  },

}); 

// ---------------

export const { 
  setUsersListLength, 
  setSelectedUser, 
  setResetUserAccess,
  setRefreshUsersList } = userAccessSlice.actions;

export default userAccessSlice.reducer;