import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from './../../../redux/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components
import Menu from './insurers/menu/Menu';

const InsurersInterface: React.FC = () => {
  const subSection = useAppSelector((state) => state.navigation.subSection);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '10%';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '90%',
        height: '95%',
        left: leftOffset,
        bottom: 0,
        position: 'absolute',
        backgroundColor: '#201F1E',
        transition: 'left 0.3s',
      }}
    >
      <Menu />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '70%',
          height: '95%',
          borderRadius: 5,
          backgroundColor: '#313338',
        }}
      >
      </Box>
    </Box>
  );
};

export default InsurersInterface;