import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import DocsList from './list/DocsList';

interface FileSelectionProps {
  onSuccess: (data: string, extension: string, name: string) => void;
  searchQuery: string;
}

const PatientDocs: React.FC<FileSelectionProps> = ({ onSuccess, searchQuery }) => {
  const isPortraitMode = useMediaQuery('(orientation: portrait)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: isPortraitMode ? '5%' : '0',
        width: isPortraitMode ? '100%' : '30%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: '#313338',
        marginLeft: '2%',
      }}
    >
      <DocsList searchQuery={searchQuery} onSuccess={onSuccess} />
    </Box>
  );
};

export default PatientDocs;