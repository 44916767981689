import React from 'react';
import Box from '@mui/material/Box';

import InsertFileContainer from './items/InsertFile';


const InsertFile: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '95%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
        zindex:10000,
    }}>

        <InsertFileContainer />
        
    </Box>

  );

};

export default InsertFile;