import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { resetFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import NavigationColumn from './NavigationColumn';
import FilesSection from './FilesSection';
import MetaHeader from './MetaHeader';
import ExitButton from './ExitButton';

type Props = {
    children?: React.ReactNode;
  };


const Container: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const closeFileExplorer = () => { 

        dispatch(resetFileExplorer()); 

    }; 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            width: '60%',
            height: '75%',
            top: '12.5%',
            left: '20%',
            borderRadius: 10,
            zIndex: 2,
            backgroundColor: '#313338',
            overflowY: 'hidden',
            scrollbarWidth: 'none',
            position: 'absolute'
        }}>

            {/* Meta Components */}

            <MetaHeader />

            <ExitButton />

            {/* Sections */}

            <NavigationColumn /> 

            <FilesSection /> 

        </Box>

    );

};

export default Container;