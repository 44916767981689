import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import MenuList from './MenuList';

const Menu: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 930px)');

  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: isSmallScreen ? '60px' : '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: 'width 0.3s',
    }}>
      <MenuList />
    </Box>
  );
};

export default Menu;