import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { RootState, AppDispatch } from '../../../../../../../redux/store';
import { setFile, clearFile, setTitle, setDescription, setResetUploadFile } from '../../../../../../../redux/features/doctor/Dashboard/documents/uploadFileSlice';
import Cookies from 'js-cookie';
import debounce from 'lodash/debounce';
import Notification from '../../../../../../general/Notification';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';
import { getPatientsList } from '../../../../../../../routes/doctor/dashboard';
import axios from 'axios';

// Routes

import { uploadFile, getBlobFile } from '../../../../../../../routes/doctor/transferFiles';

// MUI Import

import { FormControl, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const InsertFile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { file, title, description } = useSelector((state: RootState) => state.uploadFile);

  const [patients, setPatients] = useState<Array<any>>([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [uploadedFileData, setUploadedFileData] = useState<{ url: string; extension: string; name: string } | null>(null);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Fetch patients list when component loads
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const data = await getPatientsList();
        if (data) {
          setPatients(data);
        }
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    fetchPatients();
  }, []);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const allowedMimeTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (!allowedMimeTypes.includes(selectedFile.type)) {
        dispatch(setNotificationMessage(t('invalidFileType')));
        return;
      }

      dispatch(setFile(selectedFile));
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !!file,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
  });

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTitle(event.target.value));
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDescription(event.target.value));
  };

  const handlePatientSelect = (event: SelectChangeEvent<string>) => {
    setSelectedPatient(event.target.value as string);
  };

  const handleSelectFile = async (file) => {

    if (!file || !file._id) return;

    try {

      const res = await getBlobFile(file._id) as any;  

      if (res.status === 200) { 

        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
        const fileUrl = URL.createObjectURL(fileBlob);
        const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
        const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';
  
        // Update state with file URL and metadata

        setUploadedFileData({
          url: fileUrl,
          extension: fileExtension,
          name: fileName,
        });

      };

    } catch (error) {
      console.error('Error serving file:', error);
      dispatch(setNotificationMessage(t('errorRetrievingFile')));
    }
  };

  const debouncedUploadFile = debounce(async () => {
    if (!file) return;

    let finalTitle = title;
    if (!title) {
      const patient = patients.find(item => item.patientId === selectedPatient);
      const currentDate = new Date().toLocaleString();
      const patientName = patient ? `${patient.firstName} ${patient.lastName}` : t('unknownPatient');
      finalTitle = file.name.replace(/\.(pdf|docx)$/, '');;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', finalTitle);
    formData.append('description', description);
    formData.append('patientId', selectedPatient);

    try {

      const res = await uploadFile(formData) as any;  

      if (res.status === 200) {
        
        // Call handleSelectFile after the upload is successful
        handleSelectFile(res.data.file);

        dispatch(setNotificationMessage(t('fileUploaded')));
        dispatch(clearFile());

      } else {
        dispatch(setNotificationMessage(t('fileUploadFailed')));
      }
    } catch (error) {
      dispatch(setNotificationMessage(t('fileUploadFailed')));
    }
  }, 300);
  

  const handleCancel = () => {
    dispatch(clearFile());
  };

  const renderFile = () => {

    if (!uploadedFileData) {
      return null
    };
  
    if (uploadedFileData.extension === 'pdf') {
      return (
        <iframe
          ref={iframeRef}
          src={uploadedFileData.url}
          style={{ width: '100%', height: '80vh' }}
          title="PDF Viewer"
        />
      );
    }
  
    if (uploadedFileData.extension === 'docx') {
      return (
        <div
          ref={containerRef}
          style={{ width: '100%', height: '80vh', overflow: 'auto', backgroundColor: '#fff' }}
        />
      );
    }
  
    return null;
  };

  const handleGoBack = () => {
    setResetUploadFile();        
    setUploadedFileData(null);  
    setTitle('');         
    setDescription('');   
    setSelectedPatient(''); 
  };

  const handlePrint = () => {
    if (uploadedFileData?.extension === 'pdf' && iframeRef.current) {
      iframeRef.current.contentWindow?.print();
    } else if (uploadedFileData?.extension === 'docx' && containerRef.current) {
      const printWindow = window.open('', '_blank');
      printWindow?.document.write(containerRef.current.innerHTML);
      printWindow?.document.close();
      printWindow?.print();
      printWindow?.close();
    }
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',   // Adjust to cover entire width
        height: '100%',  // Adjust to cover entire height
        borderRadius: 5,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#313338',
        padding: 5,
        backgroundColor: '#383A40',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: 'white' }}>
        {t('uploadFile')}
      </Typography>
  
      {!uploadedFileData ? (
        !file ? (
          <div
            {...getRootProps()}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              borderWidth: '2px',
              borderStyle: 'dashed',
              borderColor: 'darkgrey',
              borderRadius: '5px',
              padding: '20px',
              textAlign: 'center',
              backgroundColor: '#313338',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              transition: 'border 0.3s ease',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#0099cc')}
            onMouseLeave={(e) => (e.currentTarget.style.borderColor = 'darkgrey')}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body1" sx={{ color: 'white' }}>
                {t('dropFilesHere')}
              </Typography>
            ) : (
              <>
                <CloudDownloadIcon sx={{ fontSize: 80, color: 'darkgrey' }} />
                <Typography variant="body1" sx={{ color: 'darkgrey' }}>
                  {t('dragAndDrop')}
                </Typography>
              </>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              height: '100%',
            }}
          >
            <Typography variant="body2" sx={{ mb: 1, color: 'gray', fontStyle: 'italic' }}>
              {t('uploading', { fileName: file.name })}
            </Typography>
  
            <TextField
              label={t('title')}
              value={title}
              onChange={handleTitleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{ backgroundColor: '#313338', borderRadius: 1, input: { color: 'white' }, label: { color: 'darkgrey' } }}
            />
            
            <TextField
              label={t('description')}
              value={description}
              onChange={handleDescriptionChange}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              sx={{ backgroundColor: '#313338', borderRadius: 1, input: { color: 'white' }, label: { color: 'darkgrey' } }}
            />
  
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'darkgrey' }}>{t('selectPatient')}</InputLabel>
              <Select
                value={selectedPatient}
                onChange={handlePatientSelect}
                label={t('selectPatient')}
                sx={{
                  backgroundColor: '#313338',
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': { borderColor: 'darkgrey' },
                  '.MuiSvgIcon-root': { color: 'white' },
                }}
              >
                {patients.map((item) => (
                  <MenuItem key={item.patientId} value={item.patientId}>
                    {/* {patient.firstName} {patient.lastName} */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                width: '100%',
              }}
            >
              <Button
                onClick={debouncedUploadFile}
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: '#0099cc',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#0077aa',
                  },
                  width: '45%',
                }}
              >
                {t('upload')}
              </Button>
              <Button
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
                sx={{
                  ml: 2,
                  color: '#ff5555',
                  borderColor: '#ff5555',
                  '&:hover': {
                    borderColor: '#ff3333',
                  },
                  width: '45%',
                }}
              >
                {t('cancel')}
              </Button>
            </Box>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {renderFile()}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row', 
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '16px', 
            }}
          >
            <IconButton
              onClick={handleGoBack}
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#0099cc',
                },
                mr: 2, 
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                const link = document.createElement('a');
                link.href = uploadedFileData.url;
                link.download = uploadedFileData.name || 'file';
                link.click();
              }}
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#0099cc',
                },
                mr: 2, 
              }}
            >
              <DownloadIcon />
            </IconButton>

            <IconButton
              onClick={handlePrint}
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#0099cc',
                },
              }}
            >
              <PrintIcon />
            </IconButton>
          </div>
        </div>

      )}
  
      <Notification />
    </Box>
  );
  
  
};

export default InsertFile;