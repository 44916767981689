import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Routes

import { createPatient } from '../../../../../routes/doctor/dashboard';
import { setErroredCategory, setErroredField, setResetCreatePatient } from '../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { addPatient } from '../../../../../redux/features/doctor/Dashboard/patients/patientList';
import { setPatient } from '../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setSelectedPatient } from '../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setPatientSection } from '../../../../../redux/features/doctor/Dashboard/patients/patient';

// Redux 

const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const section = useAppSelector(state => state.createPatient.section) as string; 
    const patient = useAppSelector((state) => state.createPatient) as any; 

    const [allowSubmitButton, setAllowSubmitButton] = useState(false); 

  useEffect(() => { 

    if (section === 'confirmation') { 

        setAllowSubmitButton(true); 

    } else { 

        setAllowSubmitButton(false); 

    }; 

  },[section]); 

  // -- 

  const handlePatientCreation = async () => { 

    const res = await createPatient(patient) as any; 

    if (res.data.status === '200') { 

      dispatch(setNotificationMessage(t('createPatientOK')));

      dispatch(setResetCreatePatient());

      dispatch(addPatient(res.data.newPatient));
      dispatch(setPatient(res.data.newPatient._id));
      dispatch(setSelectedPatient(res.data.newPatient._id));
      dispatch(setPatientSection(('form')));

    } else if (res.data.status === '401') { 

      if (res.data.erroredCategories) { 

        res.data.erroredCategories.forEach((category) => { 

          dispatch(setErroredCategory(category)); 

        }); 

        res.data.erroredFields.forEach((field) => { 

          dispatch(setErroredField(field)); 

        }); 

      };

    }; 

  }; 


  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 1,
        height: '5vh',
        width: '100%',
    }}>

        { allowSubmitButton ? 
        <Button 
          onClick={() => handlePatientCreation()}
          sx={{ width: '75%' }} 
          variant="contained">{t('createPatient')}</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            width: '75%',
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">{t('createPatient')}</Button>}

    </Box>

  );

};

export default SubmitButton;