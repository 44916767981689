import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

//  Routes 

import { getDoctorRecentFiles, getFolderData, searchFileExplorer } from '../../../../../../routes/doctor/files';

// Redux 

import { setCurrentFolder, setFolderData, setUnconfirmedSelection, setSearchSection, setRefreshFileExplorer } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import FolderDataRow from './FolderDataRow';

import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';



const List: React.FC = () => {
    
    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const folderData = useAppSelector((state) => state.fileExplorer.folderData); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const directory = useAppSelector((state) => state.fileExplorer.directory); 
    const searchQuery  = useAppSelector((state) => state.fileExplorer.searchQuery); 
    const refreshFileExplorer = useAppSelector((state) => state.fileExplorer.refreshFileExplorer); 
    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 

    // -- 

    const handleSearch = async () => { 

        const res = await searchFileExplorer(directory[directory.length - 1], searchQuery) as any; 

        if (res?.data.status === '200') { 
    
            dispatch(setFolderData(res.data.dataList));
    
        }; 

    }; 

    // -- 

    const handleFileSelection = (e: any, item: any) => { 

        if (e.detail === 2) { 

            if (item._doc.fileUrl) { 

                dispatch(setUnconfirmedSelection(item._doc)); 

            } else  { 

                dispatch(setSearchSection(item._doc._id)); 
                dispatch(setCurrentFolder(item._doc)); 

            };

        } else if (e.detail === 1) { 

            if (item._doc.fileUrl) { 

                dispatch(setUnconfirmedSelection(item._doc)); 

            };

        }; 

    }; 

    // --

    const getList = async () => { 

        if (searchSection === 'recent') { 

            const res = await getDoctorRecentFiles() as any; 

            if (res?.data.status === '200') { 
        
                dispatch(setFolderData(res.data.dataList));
        
            }; 

        } else if (searchSection === 'patients') { 

            dispatch(setFolderData([])); 

        } else { 

            const res = await getFolderData(searchSection); 

            if (res?.data.status === '200') { 

                dispatch(setFolderData(res.data.dataList));

            }; 

        }; 

        dispatch(setRefreshFileExplorer(false)); 

    };

    // -- 

    useEffect(() => { 

        getList(); 

    },[searchSection]);

    // -- 

    useEffect(() => { 

        if (refreshFileExplorer) { 

            getList(); 

        }; 

    },[refreshFileExplorer]); 

    // -- 

    useEffect(() => { 

        if (searchQuery) { 

            handleSearch(); 

        } else { 

            getList(); 

        }; 

    },[searchQuery]); 

    // --

    return (

        <>

        { folderData?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none'
                }}>
        
                <MuiList dense disablePadding>
        
                {folderData?.map((item, index) => (
        
                    <ListItem onClick={(e) => handleFileSelection(e, item)} button key={index} disablePadding>

                        <FolderDataRow 
                            _id={item._doc._id}
                            title={item._doc.title}
                            fileUrl={item._doc.fileUrl}
                            description={item._doc.description}
                            uploadedBy={item._doc.uploadedBy}
                            sentTo={item._doc.sentTo}
                            relatedPatient={item._doc.relatedPatient}
                            createdAt={item._doc.createdAt}
                            updatedAt={item._doc.updatedAt}
                            type={item.type}
                            searchQuery={item.searchQuery}
                        />
       
                    </ListItem>

                ))}
        
                </MuiList>
        
            </Box>}

            {(folderData?.length === 0 && !isOpened) &&   
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        height: '100%',
                    }}>
                
                    <Box
                        sx={{
                            width: 252,
                            height: 214, 
                            marginTop: '5%',
                        }}
                        component="img"
                        alt="Solutions Medca Inc."
                        src={require('../../../../../../assets/logos/empty_list.png')}
                    />

                    <Box sx={{ 
                        color: '#C2C5C8',
                        fontSize: 12,
                        marginBottom: '20%',
                    }}>{t('emptyFolder')}</Box>

                </Box>}

          </>

    );

};

export default List;