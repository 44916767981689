import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';

const Faq: React.FC = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!ready) {
    return <div>Loading...</div>;
  }

  const faqContent = t('faqContent', { returnObjects: true }) as any[];

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        mt: 4,
        mb: 4,
        color: '#fff',
        backgroundColor: '#333',
        padding: '40px',
        borderRadius: '8px',
      }}
    >
      <Box position="absolute" top={16} left={16}>
        <Link
          component="button"
          variant="body2"
          onClick={handleGoBack}
          sx={{
            color: 'gray',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <ArrowBackIosIcon fontSize="small" sx={{ mr: 0.5 }} />
          {t('goBack')}
        </Link>
      </Box>
      <Box position="absolute" top={16} right={16}>
        <LanguageSelector />
      </Box>
      <Box position="absolute" top={16} right={150}>
        <ThemeToggle />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Logo size={30} />
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          FAQ
        </Typography>

        <Box sx={{ textAlign: 'justify', width: '100%' }}>
          {faqContent.map((faq, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {faq.title}
              </Typography>
              <Typography variant="body1">{faq.description}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Faq;