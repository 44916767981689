import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PatientsArray {
  _id: string,
  firstName: string,
  lastName: string,
  email: string,
}

interface FilesArray {
  _id: string,
  title: string,
  fileUrl: string,
  description: string,
  uploadedBy: string,
  sentTo: string,
  relatedPatient: string,
}

interface File {
  _id: string,
  title: string,
  fileUrl: string,
  description: string,
  uploadedBy: string,
  sentTo: string,
  relatedPatient: string,
}

interface Insurer {
  _id: string,
  name: string,
  address?: string,
  phoneNumber?: string,
  email?: string,
}

interface InitialState {
  searchSection: string,
  patientId: string,
  fileId: string,
  selectedFile: File | null,
  patientName: string,
  recentFiles: Array<FilesArray>,
  patients: Array<PatientsArray>,
  patientFiles: Array<FilesArray>,
  selectedInsurer: Insurer | null,
}

const initialState: InitialState = {
  searchSection: 'recent',
  patientId: '',
  fileId: '',
  selectedFile: null,
  patientName: '',
  recentFiles: [],
  patients: [],
  patientFiles: [],
  selectedInsurer: null,
};

export const transferFileSlice = createSlice({
  name: 'transferFile',
  initialState,
    reducers: {
    setSearchSection: (state, action: PayloadAction<string>) => {
        state.searchSection = action.payload
    },

    selectPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload
    },

    selectFile: (state, action: PayloadAction<string>) => {
      state.fileId = action.payload
    },
    
    selectTransferFile: (state, action: PayloadAction<File>) => {
      state.selectedFile = action.payload
    },

    setPatientName: (state, action: PayloadAction<string>) => { 
      state.patientName = action.payload
    },

    setDoctorRecentFiles: (state, action: PayloadAction<any>) => { 
      state.recentFiles = action.payload
    }, 

    setPatientFiles: (state, action: PayloadAction<any>) => { 
      state.patientFiles = action.payload
    },

    selectInsurer: (state, action: PayloadAction<Insurer>) => {
      state.selectedInsurer = action.payload;
    },
    setPatientList: (state, action: PayloadAction<any>) => {
      state.patients = action.payload;
    },
    setResetPatientId: (state) => {
      state.patientId = '';
    },
    setResetTransferFile: (state) => { 
      state = initialState; 
    },
    removeFile(state, action: PayloadAction<string>) {
      state.recentFiles = state.recentFiles.filter(file => file._id !== action.payload);
    },
  },
});

export const {
  setSearchSection,
  selectPatientId,
  selectFile,
  setPatientName,
  setDoctorRecentFiles,
  setPatientFiles,
  selectInsurer,
  selectTransferFile,
  setPatientList,
  setResetPatientId,
  setResetTransferFile,
  removeFile,
} = transferFileSlice.actions;

export default transferFileSlice.reducer;