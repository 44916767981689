import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setRefreshFileExplorer } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetCreateFolder, setTitle } from '../../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Routes 

import { createFolder } from '../../../../../../routes/doctor/files';

// Components

import FolderIcon from '@mui/icons-material/Folder';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const CreateFolder: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 

    const title = useAppSelector((state) => state.createFolder.title); 
    const description = useAppSelector((state) => state.createFolder.description); 
    const folder = useAppSelector((state) => state.createFolder.folder); 
    const relatedPatient = useAppSelector((state) => state.createFolder.relatedPatient); 

    const [titleInput, setTitleInput] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');  

    // -- 

    const handleFolderCreation = async () => { 

        if (title) { 

            const content = { 
                title: title,
                description: description,
                folder: currentFolder,
                relatedPatient: relatedPatient
            }; 

            const res = await createFolder(content) as any; 

            if (res.data.status === '200') { 

                dispatch(setRefreshFileExplorer(true)); 

                dispatch(resetCreateFolder()); 

            } else if (res.data.status === '401') { 

                if (res.data.error === 'existing_folder') { 

                    setError(true); 
                    setErrorMessage(String(t('existingFolderError'))); 

                } else if (res.data.error === 'invalid_title') { 

                    setError(true); 
                    setErrorMessage(String(t('invalidTitleError'))); 

                };

            }; 

        }; 

    }; 

    // --

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleFolderCreation(); 

        }; 

    };

    // -- 

    const closeFolderCreation = () => { 

        dispatch(resetCreateFolder()); 

    }; 

    // -- 

    useEffect(() => {
        
        setError(false); 
        setErrorMessage(''); 

        dispatch(setTitle(titleInput)); 

    },[titleInput]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            fontSize: 12, 
            marginTop: 1, 
            height: 50,
        }}>
            
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>
    
                    <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 17 }} />
    
                </div>
    
                <TextField 
                    sx={{ width: '40%', textAlign: 'left' }} 
                    onChange={(e) => setTitleInput(e.target.value)}
                    id="standard-basic" 
                    value={title}
                    variant="standard" 
                    autoFocus={true}
                    error={error}
                    helperText={errorMessage}
                    onKeyDown={(e) => handleInput(e.key)}
                />

            { titleInput ? 
            <Button sx={{ marginLeft: 4, height: 30 }} onClick={() => handleFolderCreation()} variant="contained">{t('createFolder')}</Button>: 
            <Button 
            disabled={true}
            sx={{ 
                marginLeft: 4,
                height: 30,
                backgroundColor: 'transparent',
                borderColor: 'lightblue',
            }}
            variant="outlined">{t('createFolder')}</Button>}

            <Button 
                onClick={() => closeFolderCreation()} 
                sx={{ 
                    marginLeft: 1,
                    height: 30,
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    '&:hover': 
                    { 
                        backgroundColor: 'rgb(147, 147, 147)',
                        color: 'rgb(207, 207, 207)',
                        borderColor: 'rgb(147, 147, 147)'
                    }
                }}
            variant="contained">{t('cancel')}</Button>
        
        </Box>

    );

};

export default CreateFolder;