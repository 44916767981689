import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import FileOptions from './FileOptions';


type Props = {
    fileId?: string, 
    title?: string;
    description?: string;
    fileUrl?: string;
    status?: string; 
    folder?: string; 
    uploadedBy?: string;
    assignedTo?: string;
    sentTo?: string;
    sentAt?: Date;
    relatedPatient?: string;
    createdAt?: Date;
    updatedAt?: Date;
};

const FileBox: React.FC<Props> = ({ fileId, title }) => {

    const selectedFile = useAppSelector(state => state.overview.selectedFile); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '10vw',
            height: '30vh',
            marginTop: '3vh', 
            marginBottom: '3vh', 
            marginLeft: '2vh',
            marginRight: '2vh',
            borderRadius: 5,
            cursor: 'pointer',
            color: '#a6a6a6',
            backgroundColor: 'rgb(83, 82, 92)', 
            fontSize: 12,
            '&:hover': { backgroundColor: 'rgb(72, 70, 79)', color: '#e0e0e0' }
        }}>

        <div style={{ marginTop: 2 }}>{title}</div>

        </Box>

  );

};

export default FileBox;