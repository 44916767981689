import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setEmergencyContactName, setEmergencyContactRelation, setEmergencyContactPhone } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const EmergencyContact: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);
    
    const emergencyContactName = useAppSelector((state) => state.createPatient.emergencyContactName); 
    const emergencyContactRelation = useAppSelector((state) => state.createPatient.emergencyContactRelation); 
    const emergencyContactPhone = useAppSelector((state) => state.createPatient.emergencyContactPhone); 

    const [emergencyContactNameError, setEmergencyContactNameError] = useState(false); 
    const [emergencyContactRelationError, setEmergencyContactRelationError] = useState(false); 
    const [emergencyContactPhoneError, setEmergencyContactPhoneError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'emergencyContactName') { 

            dispatch(setEmergencyContactName(input)); 

            dispatch(removeErroredField('emergencyContactName'));

        } else if (section === 'emergencyContactRelation') { 

            dispatch(setEmergencyContactRelation(input)); 

            dispatch(removeErroredField('emergencyContactRelation'));

        } else if (section === 'emergencyContactPhone') { 

            dispatch(setEmergencyContactPhone(input)); 

            dispatch(removeErroredField('emergencyContactPhone'));

        };

    };

    // -- 

    useEffect(() => { 

        if (emergencyContactName && emergencyContactRelation && emergencyContactPhone) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (emergencyContactName || emergencyContactRelation || emergencyContactPhone) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[emergencyContactName, emergencyContactRelation, emergencyContactPhone]); 

    useEffect(() => { 

        erroredFields?.includes('emergencyContactName') ? setEmergencyContactNameError(true) : setEmergencyContactNameError(false);
        erroredFields?.includes('emergencyContactRelation') ? setEmergencyContactRelationError(true) : setEmergencyContactRelationError(false);
        erroredFields?.includes('emergencyContactPhone') ? setEmergencyContactPhoneError(true) : setEmergencyContactPhoneError(false);

    },[erroredFields]); 



    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('emergencyContactName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={emergencyContactName}
                onChange={(e) => handleInput(e.target.value, 'emergencyContactName')}
                fullWidth
                error={emergencyContactNameError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('emergencyContactRelation')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={emergencyContactRelation}
                onChange={(e) => handleInput(e.target.value, 'emergencyContactRelation')}
                fullWidth
                error={emergencyContactRelationError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('emergencyContactPhone')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={emergencyContactPhone}
                onChange={(e) => handleInput(e.target.value, 'emergencyContactPhone')}
                fullWidth
                error={emergencyContactPhoneError}
                variant="outlined"
            />

        </Box>
    );
};

export default EmergencyContact;