import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setIsOpened, resetCreateFolder } from '../../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Components 

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ListMetaHeader: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 

    // -- 

    const handleFolderCreation = () => { 

        if (isOpened) { 

            dispatch(resetCreateFolder()); 

        } else { 

            dispatch(setIsOpened(true));
            
        };

    }; 

    // --

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: '70%', 
            fontSize: 20,
        }}>

            <Box 
                onClick={handleFolderCreation}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 12,
                marginLeft: 3,
                padding: 1, 
                borderRadius: 3, 
                cursor: searchSection === 'patients' ? 'default' : 'pointer',
                height: 30,
                '&:hover': { backgroundColor: searchSection === 'patients' ? 'transparent' : '#4D4B59' }
            }}>
                <AddCircleOutlineIcon 
                    sx={{ fontSize: 20, color: searchSection === 'patients' ? 'rgba(194, 197, 200, 0.5)' : 'rgb(80,199,199)' }} /> 
                <Box sx={{ marginLeft: 1, color: searchSection === 'patients' ? 'rgba(194, 197, 200, 0.5)' : '#C2C5C8' }}>{t('newFolder')}</Box>
            </Box>
            
        </Box>

    );

};

export default ListMetaHeader;