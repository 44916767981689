import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setFamilyMedicalHistory, setMentalHealthHistory, setAllergies,
    setBloodType, setKnownConditions, setSurgeries, setImmunizations, setDisabilities,
    setGeneticDisorders } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const MedicalHistory: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const familyMedicalHistory = useAppSelector((state) => state.createPatient.familyMedicalHistory); 
    const mentalHealthHistory = useAppSelector((state) => state.createPatient.mentalHealthHistory);
    const allergies = useAppSelector((state) => state.createPatient.allergies);
    const bloodType = useAppSelector((state) => state.createPatient.bloodType);
    const knownConditions = useAppSelector((state) => state.createPatient.knownConditions);
    const surgeries = useAppSelector((state) => state.createPatient.surgeries);
    const immunizations = useAppSelector((state) => state.createPatient.immunizations);
    const disabilities = useAppSelector((state) => state.createPatient.disabilities);
    const geneticDisorders = useAppSelector((state) => state.createPatient.geneticDisorders); 

    const [familyMedicalHistoryError, setFamilyMedicalHistoryError] = useState(false); 
    const [mentalHealthHistoryError, setMentalHealthHistoryError] = useState(false); 
    const [allergiesError, setAllergiesError] = useState(false); 
    const [bloodTypeError, setBloodTypeError] = useState(false); 
    const [knownConditionsError, setKnownConditionsError] = useState(false); 
    const [surgeriesError, setSurgeriesError] = useState(false); 
    const [immunizationsError, setImmunizationsError] = useState(false); 
    const [disabilitiesError, setDisabilitiesError] = useState(false); 
    const [geneticDisordersError, setGeneticDisordersError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'familyMedicalHistory') { 

            dispatch(setFamilyMedicalHistory(input)); 

            dispatch(removeErroredField('familyMedicalHistory'));

        } else if (section === 'mentalHealthHistory') { 

            dispatch(setMentalHealthHistory(input)); 

            dispatch(removeErroredField('mentalHealthHistory'));

        } else if (section === 'allergies') { 

            dispatch(setAllergies(input)); 

            dispatch(removeErroredField('allergies'));

        } else if (section === 'bloodType') { 

            dispatch(setBloodType(input)); 

            dispatch(removeErroredField('bloodType'));

        } else if (section === 'knownConditions') { 

            dispatch(setKnownConditions(input)); 

            dispatch(removeErroredField('exerciseFrequency'));

        } else if (section === 'surgeries') { 

            dispatch(setSurgeries(input));
            
            dispatch(removeErroredField('surgeries'));

        } else if (section === 'immunizations') { 

            dispatch(setImmunizations(input)); 

            dispatch(removeErroredField('immunizations'));

        } else if (section === 'disabilities') { 

            dispatch(setDisabilities(input)); 

            dispatch(removeErroredField('disabilities'));

        } else if (section === 'geneticDisorders') { 

            dispatch(setGeneticDisorders(input)); 

            dispatch(removeErroredField('geneticDisorders'));

        }; 

    };

    // -- 

    useEffect(() => { 

        if (familyMedicalHistory && mentalHealthHistory && allergies && bloodType && knownConditions && surgeries && immunizations && disabilities && geneticDisorders) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (familyMedicalHistory || mentalHealthHistory || allergies || bloodType || knownConditions || surgeries || immunizations || disabilities || geneticDisorders) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[familyMedicalHistory, mentalHealthHistory, allergies, bloodType, knownConditions, surgeries, immunizations, disabilities, geneticDisorders]); 

    // -- 

    useEffect(() => { 

        erroredFields?.includes('familyMedicalHistory') ? setFamilyMedicalHistoryError(true) : setFamilyMedicalHistoryError(false);
        erroredFields?.includes('mentalHealthHistory') ? setMentalHealthHistoryError(true) : setMentalHealthHistoryError(false);
        erroredFields?.includes('allergies') ? setAllergiesError(true) : setAllergiesError(false);
        erroredFields?.includes('bloodType') ? setBloodTypeError(true) : setBloodTypeError(false);
        erroredFields?.includes('knownConditions') ? setKnownConditionsError(true) : setKnownConditionsError(false);
        erroredFields?.includes('surgeries') ? setKnownConditionsError(true) : setKnownConditionsError(false);
        erroredFields?.includes('immunizations') ? setImmunizationsError(true) : setImmunizationsError(false);
        erroredFields?.includes('disabilities') ? setDisabilitiesError(true) : setDisabilitiesError(false);
        erroredFields?.includes('geneticDisorders') ? setGeneticDisordersError(true) : setGeneticDisordersError(false);

    },[erroredFields]); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('condition')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={familyMedicalHistory}
                onChange={(e) => handleInput(e.target.value, 'familyMedicalHistory')}
                fullWidth
                error={familyMedicalHistoryError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('condition')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={mentalHealthHistory}
                onChange={(e) => handleInput(e.target.value, 'mentalHealthHistory')}
                fullWidth
                error={mentalHealthHistoryError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('allergyName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={allergies}
                onChange={(e) => handleInput(e.target.value, 'allergies')}
                fullWidth
                error={allergiesError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('bloodType')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={bloodType}
                onChange={(e) => handleInput(e.target.value, 'bloodType')}
                fullWidth
                error={bloodTypeError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('knownConditionName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={knownConditions}
                onChange={(e) => handleInput(e.target.value, 'knownConditions')}
                fullWidth
                error={knownConditionsError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('surgeryType')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={surgeries}
                onChange={(e) => handleInput(e.target.value, 'surgeries')}
                fullWidth
                error={surgeriesError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('immunizationName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={immunizations}
                onChange={(e) => handleInput(e.target.value, 'immunizations')}
                fullWidth
                error={immunizationsError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('disabilityName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={disabilities}
                onChange={(e) => handleInput(e.target.value, 'disabilities')}
                fullWidth
                error={disabilitiesError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('geneticDisorderName')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={geneticDisorders}
                onChange={(e) => handleInput(e.target.value, 'geneticDisorders')}
                fullWidth
                error={geneticDisordersError}
                variant="outlined"
            />

        </Box>
    );
};

export default MedicalHistory;