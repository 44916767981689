import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../../../util/style/getRandomColor';

// Components 

import OptionRow from './OptionRow';

// Icons 

import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PersonIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const FileViewerOptions: React.FC = () => {

    const selectedFile = useAppSelector(state => state.overview.selectedFile); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '15vw',
            top: '10vh',
            left: '5vw',
            position: 'absolute', 
            zIndex: 1,
            borderRadius: 5, 
            backgroundColor: '#525659',
            paddingTop: 2,
            paddingBottom: 2
        }}>

            <OptionRow title={'Edit File'}>
                <EditIcon  sx={{ fontSize: 20 }} /> 
            </OptionRow>

            <OptionRow title={'View In Files'}>
                <FolderIcon  sx={{ fontSize: 20 }} /> 
            </OptionRow>

            <OptionRow title={'Attach to Patient'}>
                <PersonIcon  sx={{ fontSize: 20 }} /> 
            </OptionRow>

            <OptionRow title={'Print File'}>
                <PrintIcon  sx={{ fontSize: 20 }} /> 
            </OptionRow>

            <OptionRow title={'Save File'}>
                <SaveAltIcon  sx={{ fontSize: 20 }} /> 
            </OptionRow>

    </Box>

  );

};

export default FileViewerOptions;