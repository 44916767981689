import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const Title: React.FC = () => {

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: 50,
        width: '30vw',
        fontSize: 30,
        fontWeight: 'bold',
        position: 'absolute',
        top: '40vh',
        left: '15vw',
        color: 'rgb(217, 217, 217)',
        textAlign: 'left',
      }}
    > 
      <Box>{t('slogan')}</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '5vh',
          fontSize: 15,
          color: 'rgb(160, 160, 160)',
        }}
      >
        <Box sx={{
          marginRight: 1
        }}>{t('createAccount')}
        </Box>
        <Button
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            textDecoration: 'none',
            color: 'rgb(9,109,108)', 
            textTransform: 'none',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#05E5D3',
            },
          }}
          href="https://medca.ai/contact"
          target="_blank">{t('contactTeam')}
        </Button>
      </Box>
    </Box>
  );
};

export default Title;