import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';

// Components 

import Template from './items/Template';

const Templates: React.FC = () => {

  const [templatesList, setTemplatesList] = useState([]) as any; 


  useEffect(() => { 

    setTemplatesList([1, 2, 3, 4 ,5, 6, 7, 8, 9, 10, 11, 12]); 

  },[]); 

  return (

    <Box sx={{ 
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      overflowWrap: 'normal',
      width: '95%',
      height: '95%', 
      borderRadius: 5,
      backgroundColor: '#383A40',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
    }}>

        {templatesList?.map((item, index) => (

            <Template number={index + 1} key={index}/>

        ))}

    </Box>
    
  );

};

export default Templates;