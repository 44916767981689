import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField, setErroredCategory, setErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setLanguagePreference, setPrimaryDoctor, setAdvancedDirectives, setNotes } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ExtraInformation: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const languagePreference  = useAppSelector((state) => state.createPatient.languagePreference ); 
    const primaryDoctor = useAppSelector((state) => state.createPatient.primaryDoctor); 
    const advancedDirectives = useAppSelector((state) => state.createPatient.advancedDirectives); 
    const notes = useAppSelector((state) => state.createPatient.notes); 

    const [languagePreferenceError, setLanguagePreferenceError] = useState(false); 
    const [primaryDoctorError, setPrimaryDoctorError] = useState(false); 
    const [advancedDirectivesError, setAdvancedDirectivesError] = useState(false); 
    const [notesError, setNotesError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'languagePreference') { 

            dispatch(setLanguagePreference(input)); 

            dispatch(removeErroredField('languagePreference'));

        } else if (section === 'primaryDoctor') { 

            dispatch(setPrimaryDoctor(input)); 

            dispatch(removeErroredField('primaryDoctor'));

        } else if (section === 'advancedDirectives') { 

            dispatch(setAdvancedDirectives(input)); 

            dispatch(removeErroredField('advancedDirectives'));

        } else if (section === 'notes') { 

            dispatch(setNotes(input)); 

            dispatch(removeErroredField('notes'));

        };

    };

    // -- 

    useEffect(() => { 

        if (languagePreference && primaryDoctor && advancedDirectives && notes) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (languagePreference || primaryDoctor || advancedDirectives || notes) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[languagePreference, primaryDoctor, advancedDirectives, notes]); 

    // -- 

    useEffect(() => { 

        if (erroredFields.includes('languagePreference')) { 

            setLanguagePreferenceError(true); 

        } else if (erroredFields.includes('primaryDoctor')) { 

            setPrimaryDoctorError(true); 

        } else if (erroredFields.includes('advancedDirectives')) { 

            setAdvancedDirectivesError(true); 

        } else if (erroredFields.includes('notes')) { 

            setNotesError(true); 

        }; 


    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('languagePreference')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={languagePreference }
                onChange={(e) => handleInput(e.target.value, 'languagePreference')}
                fullWidth
                error={languagePreferenceError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('primaryDoctor')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={primaryDoctor}
                onChange={(e) => handleInput(e.target.value, 'primaryDoctor')}
                fullWidth
                error={primaryDoctorError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('advancedDirectives')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={advancedDirectives}
                onChange={(e) => handleInput(e.target.value, 'advancedDirectives')}
                fullWidth
                error={advancedDirectivesError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('notes')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={notes}
                onChange={(e) => handleInput(e.target.value, 'notes')}
                fullWidth
                error={notesError}
                variant="outlined"
            />

        </Box>
    );
};

export default ExtraInformation;