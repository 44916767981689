import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getAdminConfig = () => { 

    const adminJwt = Cookies.get('adminJwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${adminJwt}`,
        },
    }; 

    return config; 

}; 

// -- 

export const requestAuthCode = async (email: string, userType: string) => {

    try {

        const content = { 
            email: email,
            userType: userType
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/request-code`, content);

        return res; 

    } catch (error) {

      console.error('Error requesting code:', error);

    }; 
};

// -- 

export const verifyAuthCode = async (email: string, verificationCode: string, userType: string) => {

    try {

        const content = { 
            email: email,
            verificationCode: verificationCode,
            userType: userType
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/verify-code`, content);

        return res; 

    } catch (error) {
        console.error('Error verifying the code'); 
    }
  };

// -- 

export const verifyUserAccess = async (type: string) => { 

    try {

        const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/account`, 
            ((type === 'doctor') || (type === 'admin')) ?  getConfig() : getAdminConfig()) as any; 

        if (res.status === 200) { 

            return res; 
              
        }; 
        
    } catch (error) {
        console.error('Error fetching the teams:', error);
    };

};