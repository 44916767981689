
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setCurrentFolder, setSearchSection } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setIsOpened } from '../../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Routes

import { getDoctorRootFolders } from '../../../../../../routes/doctor/files';

// Components

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FolderRow from './FolderRow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const FoldersList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const refreshFileExplorer = useAppSelector((state) => state.fileExplorer.refreshFileExplorer); 

    const [foldersList, setFoldersList] = useState([]) as any; 

    // -- 

    const changeSection = (selectedFolder: any) => { 

        dispatch(setSearchSection(selectedFolder._id)); 
        dispatch(setCurrentFolder(selectedFolder)); 

    }; 

    // -- 

    const handleFolderCreation = () => { 

        dispatch(setIsOpened(true)); 

    }; 

    // --

    const getFoldersList = async () => { 

        const res = await getDoctorRootFolders() as any; 

        if (res?.data.status === '200') { 

            setFoldersList(res.data.folders); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        getFoldersList(); 

    },[]); 

    // -- 

    useEffect(() => { 

        if (refreshFileExplorer) { 

            getFoldersList(); 

        }; 

    },[refreshFileExplorer]); 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                height: '50%', 
                marginTop: 2,
            }}
        >

        { foldersList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>
        
                <List dense disablePadding>
        
                {foldersList?.map((item, index) => (
        
                    <ListItem onClick={() => changeSection(item)} button key={index} disablePadding>

                        <FolderRow 
                            _id={item._id}
                            title={item.title}
                            description={item.description}
                            status={item.status}
                            folder={item.folder} 
                            uploadedBy={item.uploadedBy}
                            relatedPatient={item.relatedPatient}
                            createdAt={item.createdAt}
                            updatedAt={item.updatedAt}
                        />
       
                    </ListItem>

                ))}
        
                </List>
        
            </Box>}

            {foldersList?.length === 0 &&         
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer'
                    }}>

                    <Box 
                        onClick={handleFolderCreation}
                        sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: '#C2C5C8',
                        fontSize: 12,
                        height: 30, 
                        marginTop: '10%',
                        width: '100%',
                        '&:hover': { backgroundColor: '#4D4B59' }
                    }}>
                        <AddCircleOutlineIcon sx={{ marginLeft: '10%', fontSize: 15, color: 'rgb(80,199,199)' }} /> 
                        <Box sx={{ marginLeft: 1 }}>{t('newFolder')}</Box>
                    </Box>

                </Box>}

          </Box>

    );

};

export default FoldersList;