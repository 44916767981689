import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTheme } from '../../../../../themeContext';

// Redux 

type Props = {
    children?: React.ReactNode;
};


const MetaHeader: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const { isLightMode } = useTheme();

    const logoSrc = isLightMode
      ? require('../../../../../assets/logos/medca_logo_blue_dark_blue.png')
      : require('../../../../../assets/logos/medca_logo_blue_white.png');

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            position: 'absolute',
            top: 0, 
            left: 0, 
        }}>

        <Box
            component="img"
            sx={{
                height: `15px`,
                width: 'auto',
                marginLeft: 2,
                marginTop: 1
            }}
            alt="Solutions Medca Inc."
            src={logoSrc}
        />

        </Box>

    );

};

export default MetaHeader;