import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import * as docx from 'docx-preview';

// Redux

import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Utils 

import { setPatientSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

const File: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const fileData = useAppSelector((state) => state.patient.fileData);
    const fileExtension = useAppSelector((state) => state.patient.fileExtension);  
    const fileName = useAppSelector((state) => state.patient.fileName); 

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);


    const goBackToForm = () => { 
        dispatch(setPatientSection(('form')));
    }; 

    const handlePrint = () => {
        if (fileExtension === 'pdf' && iframeRef.current) {
          iframeRef.current.contentWindow?.print();
        } else if (fileExtension === 'docx' && containerRef.current) {
          const printWindow = window.open('', '_blank');
          printWindow?.document.write(containerRef.current.innerHTML);
          printWindow?.document.close();
          printWindow?.print();
          printWindow?.close();
        }
      };

    useEffect(() => {
    if (fileExtension === 'docx' && fileData && containerRef.current) {
        fetch(fileData)
        .then(res => res.blob())
        .then(blob => docx.renderAsync(blob, containerRef.current!))
        .catch(err => console.error('Error rendering DOCX file:', err));
    }
    }, [fileData, fileExtension]);

    const renderFile = () => {
        if (!fileData || !fileExtension) return null;
    
        if (fileExtension === 'pdf') {
          return (
            <iframe
              ref={iframeRef}
              src={fileData}
              style={{ width: '100%', height: '90vh' }}
              title="PDF Viewer"
            />
          );
        }
    
        if (fileExtension === 'docx') {
          return (
            <div
              ref={containerRef}
              style={{ width: '100%', height: '90vh', overflow: 'auto', backgroundColor: '#fff' }}
            />
          );
        }
    
        return (
          <iframe
            ref={iframeRef}
            src={fileData}
            style={{ width: '100%', height: '90vh' }}
            title="File Viewer"
          />
        );
      };

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    {renderFile()}
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
      <Button
        variant="contained"
        onClick={() => goBackToForm()}
        sx={{ mr: 2 }}
      >
        Go Back
      </Button>
      {fileData && (
        <>
          <Button
            variant="contained"
            onClick={() => {
              const link = document.createElement('a');
              link.href = fileData;
              link.download = fileName || 'file';
              link.click();
            }}
            sx={{ mr: 2 }}
          >
            Download
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
          >
            Print
          </Button>
        </>
      )}
    </Box>
  </Box>
};

export default File;