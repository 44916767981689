import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';


const TransferFile: React.FC = () => {

  const dispatch = useAppDispatch(); 


  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '95%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
    }}>
        
    </Box>

  );

};

export default TransferFile;