import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import EmptySpace from './EmptySpace';
import FileViewerOptions from './FileViewerOptions';

const FileViewer: React.FC = () => {

    const blobFileUrl = useAppSelector(state => state.overview.blobFileUrl); 
    const fileExtension = useAppSelector(state => state.overview.fileExtension); 
    const fileName = useAppSelector(state => state.overview.fileName); 

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    return (

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '100vw',
                height: '100vh',
                bottom: 0,
                right: 0,
                position: 'absolute', 
                zIndex: 0,
            }}>

        <EmptySpace /> 

        
        <FileViewerOptions />


        { ((fileExtension === 'pdf') || (fileExtension !== 'docx')) && 
            <iframe
                ref={iframeRef}
                src={blobFileUrl}
                style={{ 
                    width: '50vw', 
                    height: '80vh',
                    position: 'absolute', 
                    left: '25vw', 
                    bottom: '10vh', 
                }}
                title="PDF Viewer"
        /> }
                
        { fileExtension === 'docx' && 

            <div
            ref={containerRef}
            style={{ 
                width: '50vw', 
                height: '80vh',
                position: 'absolute', 
            }}
        />}
        
        </Box>



    );

};

export default FileViewer;

  // const isMobile = useMediaQuery('(max-width:600px)');


  // const handleSelectFile = async (file) => {
  //   if (editingFileId) return;

  //   const jwtToken = Cookies.get('jwt');
  //   if (!jwtToken) {
  //     dispatch(setNotificationMessage(t('jwtNotFound')));
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_URL}/files/serve`,
  //       { fileId: file._id },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`
  //         },
  //         responseType: 'blob'
  //       }
  //     );

  //     const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
  //     const fileUrl = URL.createObjectURL(fileBlob);
  //     const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
  //     const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';

  //     onSuccess(fileUrl, fileExtension, fileName);
  //   } catch (error) {
  //     console.error('Error serving file:', error);
  //     dispatch(setNotificationMessage(t('serveFileNotOK')));
  //   }
  // };

  // FROM PATIENTSFILES.TSX 

  // const handleDocClick = async (documentId) => {
  //   const jwtToken = Cookies.get('jwt');
  //   if (!jwtToken) {
  //     dispatch(setNotificationMessage(t('jwtNotFound')));
  //     return;
  //   }

  //   try {
  //     const data = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}/files/info/${documentId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`
  //         },
  //       }
  //     );

  //     const file = data.data.file;

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_URL}/files/serve`,
  //       {
  //         fileId: file._id
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`
  //         },
  //         responseType: 'blob'
  //       }
  //     );

  //     let fileBlob, fileUrl;
  //     const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
  //     const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';

  //     if (fileExtension === 'pdf') {
  //       fileBlob = new Blob([response.data], { type: 'application/pdf' });
  //       fileUrl = URL.createObjectURL(fileBlob);
  //     } else {
  //       fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
  //       fileUrl = URL.createObjectURL(fileBlob);
  //     }

  //     onSuccess(fileUrl, fileExtension, fileName);
  //   } catch (error) {
  //     console.error('Error serving file:', error);
  //     dispatch(setNotificationMessage(t('serveFileNotOK')));
  //   }
  // };