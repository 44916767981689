import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setDataList, setSelectedFile, setBlobFileUrl, setFileExtension, setFileName, 
    setIsFileViewerOpened } from '../../../../../../redux/features/doctor/Dashboard/documents/overview';

// Routes 

import { getRecentFiles, serveFile } from '../../../../../../routes/doctor/files';

// Components 

import FileBox from './FileBox';

const FilesList: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const section = useAppSelector((state) => state.overview.section); 
    const dataList = useAppSelector((state) => state.overview.dataList); 

    // -- 

    const handleFileSelection = async (e: any, file: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedFile(file)); 

            const res = await serveFile(file._id) as any; 

            const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

            dispatch(setBlobFileUrl(URL.createObjectURL(fileBlob))); 
            dispatch(setFileExtension(file.fileUrl.split('.').pop() || 'unknown')); 
            dispatch(setFileName(file.fileUrl.split('-').slice(1).join('-') || 'file')); 
            dispatch(setIsFileViewerOpened(true)); 

            // Soviet Code for a docx file. Did not know what to do with it. 

            // useEffect(() => {
            //     if (fileExtension === 'docx' && fileData && containerRef.current) {
            //       fetch(fileData)
            //         .then(res => res.blob())
            //         .then(blob => docx.renderAsync(blob, containerRef.current!))
            //         .catch(err => console.error('Error rendering DOCX file:', err));
            //     }
            //   }, [fileData, fileExtension]);

        } else { 

            dispatch(setSelectedFile(file)); 

        }; 

    }; 

    // -- 

    const getFilesList = async () => { 

        const res = await getRecentFiles() as any; 

        if (res.data.status === '200') { 

            dispatch(setDataList(res.data.dataList)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        getFilesList(); 

    },[]); 

    return (

        <>

            { dataList?.length >= 1 && 

            <Box 
                
                sx={{ 
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    overflowWrap: 'normal',
                    width: '95%',
                    height: '95%', 
                    borderRadius: 5,
                    backgroundColor: '#383A40',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                }}>
        
                    {dataList?.map((item, index) => (

                        <Box
                            key={index}
                            onClick={(e) => handleFileSelection(e, item)}
                        >
                            <FileBox
                                fileId={item._id} 
                                title={item.title}
                            />
        
                        </Box>

                    ))}
        
            </Box>}



            {(dataList?.length === 0) &&   
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        height: '100%',
                    }}>
                
                    <Box
                        sx={{
                            width: 252,
                            height: 214, 
                            marginTop: '5%',
                        }}
                        component="img"
                        alt="Solutions Medca Inc."
                        src={require('../../../../../../assets/logos/empty_list.png')}
                    />

                    <Box sx={{ 
                        color: '#C2C5C8',
                        fontSize: 12,
                        marginBottom: '20%',
                    }}>{t('emptyFolder')}</Box>

                </Box>}

          </>

    );

};

export default FilesList;