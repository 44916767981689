import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';

const Privacy: React.FC = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!ready) {
    return <div>Loading...</div>;
  }

  const privacyContent = t('privacyContent', { returnObjects: true }) as any;

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        mt: 4,
        mb: 4,
        color: '#fff', // Set text color to white
        backgroundColor: '#333', // Set background color to dark
        padding: '40px', // Increased padding for more margin
        borderRadius: '8px',
      }}
    >
      <Box position="absolute" top={16} left={16}>
        <Link
          component="button"
          variant="body2"
          onClick={handleGoBack}
          sx={{
            color: 'gray',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <ArrowBackIosIcon fontSize="small" sx={{ mr: 0.5 }} />
          {t('goBack')}
        </Link>
      </Box>
      <Box position="absolute" top={16} right={16}>
        <LanguageSelector />
      </Box>
      <Box position="absolute" top={16} right={150}>
        <ThemeToggle />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Logo size={30} />
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          {t('privacyPolicy')}
        </Typography>
        <Box sx={{ textAlign: 'justify', width: '100%' }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.introduction}</p>
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.dataCollection?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <p>{privacyContent?.dataCollection?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataCollection?.healthcareProfessionals && (
                <li>{privacyContent.dataCollection.healthcareProfessionals}</li>
              )}
              {privacyContent?.dataCollection?.insurers && <li>{privacyContent.dataCollection.insurers}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.dataUsage?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <p>{privacyContent?.dataUsage?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataUsage?.usage1 && <li>{privacyContent.dataUsage.usage1}</li>}
              {privacyContent?.dataUsage?.usage2 && <li>{privacyContent.dataUsage.usage2}</li>}
              {privacyContent?.dataUsage?.usage3 && <li>{privacyContent.dataUsage.usage3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.dataSecurity?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <p>{privacyContent?.dataSecurity?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataSecurity?.security1 && <li>{privacyContent.dataSecurity.security1}</li>}
              {privacyContent?.dataSecurity?.security2 && <li>{privacyContent.dataSecurity.security2}</li>}
              {privacyContent?.dataSecurity?.security3 && <li>{privacyContent.dataSecurity.security3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.dataSharing?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.dataSharing?.description}</p>
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.userRights?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <p>{privacyContent?.userRights?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.userRights?.rights1 && <li>{privacyContent.userRights.rights1}</li>}
              {privacyContent?.userRights?.rights2 && <li>{privacyContent.userRights.rights2}</li>}
              {privacyContent?.userRights?.rights3 && <li>{privacyContent.userRights.rights3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.dataRetention?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.dataRetention?.description}</p>
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.cookiesAndTracking?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.cookiesAndTracking?.description}</p>
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.changesToPrivacyPolicy?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.changesToPrivacyPolicy?.description}</p>
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {privacyContent?.contactInformation?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <p>{privacyContent?.contactInformation?.description}</p>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Privacy;