import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux
 
import { setPatientSection } from '../../../../../redux/features/doctor/Dashboard/patients/patient';

// Components 

import File from './items/File';
import CreatePatientForm from '../createPatient/CreatePatientForm';
import PatientForm from './items/PatientForm';

const InfoContainer: React.FC = () => {

  const dispatch = useAppDispatch();

  const section = useAppSelector((state) => state.patient.section); 
  const selectedPatient = useAppSelector((state) => state.patient.selectedPatient);
  const isPortraitMode = useMediaQuery('(orientation: portrait)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: '#383A40',
        padding: '10px',
        boxSizing: 'border-box',
        margin: isPortraitMode ? '0 10px' : '0',
      }}
    >
        {section === 'form' && <PatientForm isPortraitMode={isPortraitMode} />}

        {section === 'file' && <File />}

        {section === 'createPatient' && <CreatePatientForm />}

    </Box>
  );
};

export default InfoContainer;
