

const getFormattedPathName = (section: string, directory: string) => { 

    const pathStart = directory.split(section); 

    if (pathStart[0]) { 

        return `${pathStart[0]}${section}`; 

    } else { 

        return section; 

    }; 

}; 

export default getFormattedPathName; 