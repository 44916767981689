
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { setCurrentFolder, setSearchSection } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Routes 

import { getRootFolder } from '../../../../../../routes/doctor/files';

// Icons 

import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import { t } from 'i18next';


// Components

type Props = {
    children?: React.ReactNode;
};


const DefaultFolders: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 

    const changeSection = async (newSection: string) => { 

        if (newSection === 'documents') { 

            const res = await getRootFolder() as any;  

            if (res?.data.status === '200') {

                dispatch(setSearchSection(res.data.folder._id)); 
                dispatch(setCurrentFolder(res.data.folder)); 

            }; 
    
        } else { 

            dispatch(setSearchSection(newSection));

        }; 

    }; 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            marginTop: 50,
            fontSize: 12,
            cursor: 'pointer'
        }}>

            <Box 
                onClick={() => changeSection('recent')}
                sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: 30,
                color: '#C2C5C8',
                backgroundColor: searchSection === 'recent' ? '#4D4B59' : 'transparent',
                '&:hover': { backgroundColor: searchSection === 'recent' ? '#4D4B59' : 'rgba(77, 75, 89, 0.5)' }
            }}>

                <ScheduleIcon sx={{ color: 'rgb(34, 148, 6)', fontSize: 15, marginLeft: 1 }} />

                <Box sx={{ fontSize: 12, marginLeft: 1 }}>{t('recent')}</Box>

            </Box>

            <Box 
                onClick={() => changeSection('patients')}
                sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: 30,
                color: '#C2C5C8',
                backgroundColor: searchSection === 'patients' ? '#4D4B59' : 'transparent',
                '&:hover': { backgroundColor: searchSection === 'patients' ? '#4D4B59' : 'rgba(77, 75, 89, 0.5)' }
            }}>

                <PersonIcon sx={{ color: 'rgb(30, 144, 255)', fontSize: 15, marginLeft: 1 }} />

                <Box sx={{ fontSize: 12, marginLeft: 1 }}>{t('byPatient')}</Box>

            </Box>

            <Box 
                onClick={() => changeSection('documents')}
                sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: 30,
                color: '#C2C5C8',
                backgroundColor: currentFolder?.path === 'Documents' ? '#4D4B59' : 'transparent',
                '&:hover': { backgroundColor: currentFolder?.path === 'Documents' ? '#4D4B59' : 'rgba(77, 75, 89, 0.5)' }
            }}>

                <DescriptionIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 1 }} />

                <Box sx={{ fontSize: 12, marginLeft: 1 }}>{t('documents')}</Box>

            </Box>
            
        </Box>

    );

};

export default DefaultFolders;






















{/* <ListItem
secondaryAction={
  <IconButton edge="end" aria-label="delete">
    <DeleteIcon />
  </IconButton>
}
>
<ListItemAvatar>
  <Avatar>
    <FolderIcon />
  </Avatar>
</ListItemAvatar>
<ListItemText
  primary="Single-line item"
  secondary={secondary ? 'Secondary text' : null}
/>
</ListItem> */}