import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    section: string, 
    dataList: any[], 
    selectedFile: any,
    blobFileUrl: any,
    fileExtension: any,  
    fileName: any, 
    isFileViewerOpened: boolean, 
}

const initialState: InitialState = {
  section: '', 
  dataList: [], 
  selectedFile: null,
  blobFileUrl: null,
  fileExtension: null, 
  fileName: null, 
  isFileViewerOpened: false, 
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
    reducers: {
    setSection: (state, action: PayloadAction<string>) => {
        state.section = action.payload
    },
    setDataList: (state, action: PayloadAction<any>) => {
        state.dataList = action.payload 
    },
    setSelectedFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload 
    },
    setBlobFileUrl: (state, action: PayloadAction<any>) => {
      state.blobFileUrl = action.payload 
    },
    setFileExtension: (state, action: PayloadAction<any>) => {
      state.fileExtension = action.payload 
    },
    setFileName: (state, action: PayloadAction<any>) => {
      state.fileName = action.payload 
    },
    setIsFileViewerOpened: (state, action: PayloadAction<boolean>) => {
      state.isFileViewerOpened = action.payload 
    },
    resetCorrectFile: (state) => { 

      state.section = '';
      state.dataList = []; 
      state.selectedFile = null; 
      state.blobFileUrl = null; 
      state.fileExtension = null; 
      state.fileName = null; 
      state.isFileViewerOpened = false; 

    } 
  },
});

export const {
  setSection, 
  setDataList, 
  setSelectedFile, 
  setBlobFileUrl, 
  setFileExtension, 
  setFileName, 
  setIsFileViewerOpened, 
  resetCorrectFile
} = overviewSlice.actions;

export default overviewSlice.reducer;