import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../../../redux/hooks';
import { getDoctorPatientDocs } from './../../../../../../../../routes/doctor/dashboard';
import { setPatientDoc } from './../../../../../../../../redux/features/doctor/Dashboard/patients/patient';
import Cookies from 'js-cookie';
import axios from 'axios';
import Fuse from 'fuse.js';
import Notification from '../../../../../../../general/Notification';
import { setNotificationMessage } from '../../../../../../../../redux/features/general/notification';

// MUI Icon
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ClearAllIcon from '@mui/icons-material/ClearAll';

interface FileSelectionProps {
  onSuccess: (data: string, extension: string, name: string) => void;
  searchQuery: string;
}

const DocsList: React.FC<FileSelectionProps> = ({ onSuccess, searchQuery }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPatient = useAppSelector(state => state.patient.selectedPatient);
  const patientDoc = useAppSelector(state => state.patient.patientDoc);
  const [patientDocsList, setPatientDocsList] = useState(Array<any>);



  useEffect(() => { 

    if (selectedPatient){

      getCurrentPatientDoc();

    };
    
  },[selectedPatient]);

  useEffect(() => { 

    setPatientDocsList(patientDoc);
  
  },[patientDoc]);

  const getCurrentPatientDoc = async () => { 
    const data = await getDoctorPatientDocs(selectedPatient); 
    dispatch(setPatientDoc(data));
  };

  const handleDocClick = async (documentId) => {
    const jwtToken = Cookies.get('jwt');
    if (!jwtToken) {
      dispatch(setNotificationMessage(t('jwtNotFound')));
      return;
    }

    try {
      const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/info/${documentId}`);
      const file = data.data.file;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/files/serve`,
        {
          fileId: file._id
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          },
          responseType: 'blob'
        }
      );

      let fileBlob, fileUrl;
      const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
      const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';

      if (fileExtension === 'pdf') {
        fileBlob = new Blob([response.data], { type: 'application/pdf' });
        fileUrl = URL.createObjectURL(fileBlob);
      } else {
        fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
        fileUrl = URL.createObjectURL(fileBlob);
      }

      onSuccess(fileUrl, fileExtension, fileName);
    } catch (error) {
      console.error('Error serving file:', error);
      dispatch(setNotificationMessage(t('serveFileNotOK')));
    }
  };

  const fuse = new Fuse(patientDocsList || [], {
    keys: ['title'],
    threshold: 0.2, // Adjust the threshold to control the fuzziness
  });
  
  const filteredDocs = searchQuery 
    ? fuse.search(searchQuery).map(result => result.item) 
    : patientDocsList || [];
  


    return (
      <Box
      sx={{
        display: filteredDocs == null ? 'flex' : 'block', // Flex when no patients, block otherwise
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden',
        width: '95%',
        marginTop: '1vh',
        height: '100vh', // Ensure the Box takes full height to center vertically
      }}
    >
        {filteredDocs && filteredDocs.length > 0 ? (
          <List dense>
             {filteredDocs.map((item, index) => (
          <ListItem button key={index} onClick={() => handleDocClick(item._id)}>
            <ListItemIcon sx={{ color: '#C2C5C8' }}>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#C2C5C8' }} primary={item.title} />
          </ListItem>
        ))}
          </List>
        ) : (
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <ClearAllIcon sx={{ color: '#C2C5C8', fontSize: 50, marginBottom: 2 }} />
          <Typography sx={{ fontSize: 12, color: '#C2C5C8' }}>
            {t('noDocuments')}
          </Typography>
        </Box>
        )}
        <Notification />
      </Box>
    );
};

export default DocsList;