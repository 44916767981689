import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { setSearchSection, setCurrentFolder } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Routes 

import { getFolderDataByPath } from '../../../../../../routes/doctor/files';

// Utils 

import getFormattedPathName from '../../../../../../util/dashboard/doctor/files/getFormattedPathName';

const Directory: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const directory = useAppSelector((state) => state.fileExplorer.directory); 
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 

    const [pathName, setPathName] = useState([]) as any; 

    // -- 

    const changeDirectory = async (section: any) => { 

        if (section !== currentFolder.title) { 

            const path = getFormattedPathName(section, directory); 

            const res = await getFolderDataByPath(path);

            if (res?.data.status === '200') {

                const { folder } = res.data; 

                if (folder) { 

                    dispatch(setSearchSection(folder._id)); 
                    dispatch(setCurrentFolder(folder)); 

                };

            };

        };

    }; 

    // -- 

    useEffect(() => { 

        if (directory.includes('/')) { 

            let list = [] as any; 

            list = directory.split('/'); 

            setPathName(list);

        } else { 

            setPathName([directory]);

        };

        console.log(directory); 

    },[directory]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '75%',
            fontSize: 12,
        }}>

        {pathName?.map((item, index) => (

             <Box 
                key={index}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',      
            }}>
        
                <Box 
                    onClick={() => changeDirectory(item)}
                    sx={{ 
                        marginLeft: 1,
                        cursor: index === directory?.length - 1 ? 'default': 'pointer', 
                        '&:hover': { color: index === directory?.length - 1 ? 'white' : 'rgb(80,199,199)' }
                    }}
                >{item}</Box>
                { ((item !== currentFolder?.title) && (item)) && <Box sx={{ marginLeft: 1 }}>{'>'}</Box>}

            </Box>

        ))}
            
        </Box>

    );

};

export default Directory;