import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';
import * as docx from 'docx-preview';

// Components 

import FilesList from './overviewItems/FilesList';

const Overview: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '95%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: '#383A40',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >

      <FilesList /> 

    </Box>
  );
};

export default Overview;