import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 
import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setHealthCardNumber, setMedicalHistory, setEmail, setPhone, setAddress } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ContactInfo: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const healthCardNumber = useAppSelector((state) => state.createPatient.healthCardNumber); 
    const medicalHistory = useAppSelector((state) => state.createPatient.medicalHistory); 
    const phone = useAppSelector((state) => state.createPatient.phone); 
    const email = useAppSelector((state) => state.createPatient.email); 
    const address = useAppSelector((state) => state.createPatient.address); 

    const [healthCardNumberError, setHealthCardNumberError] = useState(false); 
    const [medicalHistoryError, setMedicalHistoryError] = useState(false); 
    const [phoneError, setPhoneError] = useState(false); 
    const [emailError, setEmailError] = useState(false); 
    const [addressError, setAddressError] = useState(false); 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'healthCardNumber') { 

            dispatch(setHealthCardNumber(input)); 

            dispatch(removeErroredField('healthCardNumber')); 

        } else if (section === 'medicalHistory') { 

            dispatch(setMedicalHistory(input)); 

            dispatch(removeErroredField('medicalHistory')); 

        } else if (section === 'email') { 

            dispatch(setEmail(input)); 

            dispatch(removeErroredField('email')); 

        } else if (section === 'phone') { 

            dispatch(setPhone(input)); 

            dispatch(removeErroredField('phone')); 

        } else if (section === 'address') { 

            dispatch(setAddress(input)); 

            dispatch(removeErroredField('address')); 

        }; 

    };

    // -- 

    useEffect(() => { 

        if (healthCardNumber && medicalHistory && email && phone && address) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (healthCardNumber || medicalHistory || email || phone || address) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };


    },[healthCardNumber, medicalHistory, email, phone, address]); 

    // -- 

    useEffect(() => { 

        erroredFields?.includes('healthCardNumber') ? setHealthCardNumberError(true) : setHealthCardNumberError(false);
        erroredFields?.includes('medicalHistory') ? setMedicalHistoryError(true) : setMedicalHistoryError(false);
        erroredFields?.includes('phone') ? setPhoneError(true) : setPhoneError(false);
        erroredFields?.includes('email') ? setEmailError(true) : setEmailError(false);
        erroredFields?.includes('address') ? setAddressError(true) : setAddressError(false);

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>

            <TextField
                sx={{ width: '100%' }}
                label={t('healthCardNumber')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={healthCardNumber}
                onChange={(e) => handleInput(e.target.value, 'healthCardNumber')}
                error={healthCardNumberError}
                fullWidth
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('medicalHistory')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={medicalHistory}
                onChange={(e) => handleInput(e.target.value, 'medicalHistory')}
                error={medicalHistoryError}
                fullWidth
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('phone')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={phone}
                onChange={(e) => handleInput(e.target.value, 'phone')}
                error={phoneError}
                fullWidth
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('address')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={address}
                onChange={(e) => handleInput(e.target.value, 'address')}
                fullWidth
                error={addressError}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('email')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={email}
                onChange={(e) => handleInput(e.target.value, 'email')}
                fullWidth
                error={emailError}
                variant="outlined"
            />

        </Box>
    );
};

export default ContactInfo;