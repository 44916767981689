import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientState {
  patient: any,
  selectedPatient: string,
  patientDoc: any,
  section: any  // createPatient, form, file
  fileData: any,
  fileExtension: any, 
  fileName: any
};

// ---------------

const initialState: PatientState = {
  patient: null,
  selectedPatient: "",
  patientDoc: null,
  section: 'form',
  fileData: '',
  fileExtension: '',
  fileName: ''
};

// ---------------

export const patientSlice = createSlice({

  name: 'patient',

  initialState,

  reducers: {

    setPatient: (state, action: PayloadAction<any>) => {

      state.patient = action.payload; 

    },
    setSelectedPatient: (state, action: PayloadAction<any>) => {

      state.selectedPatient = action.payload; 

    },
    setPatientDoc: (state, action: PayloadAction<any>) => {
      
      state.patientDoc = action.payload;

    },
    setPatientSection: (state, action: PayloadAction<any>) => {
      
      state.section = action.payload;

    },
    setPatientFileData: (state, action: PayloadAction<any>) => {
      
      state.fileData = action.payload;

    },
    setPatientFileExtension: (state, action: PayloadAction<any>) => {
      
      state.fileExtension = action.payload;

    },
    setPatientFileName: (state, action: PayloadAction<any>) => {
      
      state.fileName = action.payload;

    },
    setResetPatient: (state) => { 
        
      state = initialState; 

    } 
  },

}); 

// ---------------

export const { setPatient, 
  setResetPatient, 
  setSelectedPatient, 
  setPatientDoc, 
  setPatientSection,
  setPatientFileData,
  setPatientFileExtension,
  setPatientFileName } = patientSlice.actions;

// export const selectCount = (state: RootState) => state.counter.value; 

export default patientSlice.reducer;