import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../../redux/hooks';
import { setPatient } from './../../../../../../../redux/features/doctor/Dashboard/patients/patient';
import moment from 'moment';
import Fuse from 'fuse.js';

// Icons 
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import SimCardIcon from '@mui/icons-material/SimCard';
import WcIcon from '@mui/icons-material/Wc';
import CakeIcon from '@mui/icons-material/Cake';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import WineBarIcon from '@mui/icons-material/WineBar';
import WorkIcon from '@mui/icons-material/Work';
import MedicationIcon from '@mui/icons-material/Medication';
import NoFoodIcon from '@mui/icons-material/NoFood';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import SickIcon from '@mui/icons-material/Sick';
import MasksIcon from '@mui/icons-material/Masks';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AccessibleIcon from '@mui/icons-material/Accessible';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import NumbersIcon from '@mui/icons-material/Numbers';
import HouseIcon from '@mui/icons-material/House';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SupportIcon from '@mui/icons-material/Support';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotesIcon from '@mui/icons-material/Notes';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { getPatientInfo, getDoctorName } from './../../../../../../../routes/doctor/dashboard';

interface HealthDataListProps {
  searchQuery: string;
}

const HealthDataList: React.FC<HealthDataListProps> = ({ searchQuery }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const patient = useAppSelector((state) => state.patient.patient);
  const selectedPatient = useAppSelector((state) => state.patient.selectedPatient);
  const [doctorName, setDoctorName] = useState<string | undefined>('');

  useEffect(() => {
    if (selectedPatient) {
      getCurrentPatientInfo();
    }
  }, [dispatch, selectedPatient]);

  const getCurrentPatientInfo = async () => {
    const data = await getPatientInfo(selectedPatient);
    dispatch(setPatient(data));
    fetchDoctorName(data.primaryDoctor);
  };

  const fetchDoctorName = async (primaryDoctorId: string) => {
    const name = await getDoctorName(primaryDoctorId);
    setDoctorName(name);
  };

  const displayAge = (dateOfBirth: string) => {
    if (!dateOfBirth) return null;
    const birthDate = moment.utc(dateOfBirth).format('LL');
    const today = moment();
    const age = today.diff(birthDate, 'years');
    return age;
  };

  const displayDateOfBirth = (dateOfBirth: string) => {
    if (!dateOfBirth) return null;
    return moment.utc(dateOfBirth).format('LL');
  };

  const listItems = [
    { type: 'header', title: t('patientInfo') },
    { text: t('dateOfBirth'), icon: <CakeIcon sx={{ color: '#FFFFFF' }} />, description: displayDateOfBirth(patient?.dateOfBirth ?? '') },
    { text: t('gender'), icon: <WcIcon sx={{ color: '#FFFFFF' }} />, description: patient?.gender ?? '' },
    { text: t('age'), icon: <CalendarMonthIcon sx={{ color: '#FFFFFF' }} />, description: displayAge(patient?.dateOfBirth ?? '') },
    { type: 'header', title: t('contactInfo') },
    { text: t('healthCardNumber'), icon: <MedicalServicesIcon sx={{ color: '#FFFFFF' }} />, description: patient?.healthCardNumber ?? '' },
    { text: t('medicalHistory'), icon: <MedicalInformationIcon sx={{ color: '#FFFFFF' }} />, description: patient?.medicalHistory ?? '' },
    { text: t('phone'), icon: <LocalPhoneIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.phone ?? '' },
    { text: t('email'), icon: <EmailIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.email ?? '' },
    { text: t('address'), icon: <HomeIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.address ?? '' },
    { type: 'header', title: t('emergyContact') },
    { text: t('name'), icon: <PersonIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.emergencyContact?.name ?? '' },
    { text: t('relation'), icon: <FamilyRestroomIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.emergencyContact?.relation ?? '' },
    { text: t('phone'), icon: <LocalPhoneIcon sx={{ color: '#FFFFFF' }} />, description: patient?.contactInfo?.emergencyContact?.phone ?? '' },
    { type: 'header', title: t('healthInformation') },
    { text: t('currentMedication'), icon: <MedicationIcon sx={{ color: '#FFFFFF' }} />, description: patient?.currentMedication?.join(', ') ?? '' },
    { text: t('medicalHistory'), icon: <MedicalInformationIcon sx={{ color: '#FFFFFF' }} />, description: patient?.medicalHistory ?? '' },
    { text: t('familyMedicalHistory'), icon: <MedicalInformationIcon sx={{ color: '#FFFFFF' }} />, description: patient?.familyMedicalHistory?.join(', ') ?? '' },
    { text: t('mentalHealthHistory'), icon: <PsychologyIcon sx={{ color: '#FFFFFF' }} />, description: patient?.mentalHealthHistory?.join(', ') ?? '' },
    { text: t('allergies'), icon: <NoFoodIcon sx={{ color: '#FFFFFF' }} />, description: patient?.allergies?.join(', ') ?? '' },
    { text: t('bloodType'), icon: <BloodtypeIcon sx={{ color: '#FFFFFF' }} />, description: patient?.bloodType ?? '' },
    { text: t('knownConditions'), icon: <SickIcon sx={{ color: '#FFFFFF' }} />, description: patient?.knownConditions?.join(', ') ?? '' },
    { text: t('surgeries'), icon: <MasksIcon sx={{ color: '#FFFFFF' }} />, description: patient?.surgeries?.join(', ') ?? '' },
    { text: t('immunizations'), icon: <VaccinesIcon sx={{ color: '#FFFFFF' }} />, description: patient?.immunizations?.join(', ') ?? '' },
    { text: t('geneticDisorders'), icon: <Diversity2Icon sx={{ color: '#FFFFFF' }} />, description: patient?.geneticDisorders?.join(', ') ?? '' },
    { text: t('disabilities'), icon: <AccessibleIcon sx={{ color: '#FFFFFF' }} />, description: patient?.disabilities ?? '' },
    { type: 'header', title: t('insuranceDetails') },
    { text: t('provider'), icon: <BusinessCenterIcon sx={{ color: '#FFFFFF' }} />, description: patient?.insuranceDetails?.provider ?? '' },
    { text: t('policyNumber'), icon: <NumbersIcon sx={{ color: '#FFFFFF' }} />, description: patient?.insuranceDetails?.policyNumber ?? '' },
    { text: t('groupNumber'), icon: <NumbersIcon sx={{ color: '#FFFFFF' }} />, description: patient?.insuranceDetails?.groupNumber ?? '' },
    { type: 'header', title: t('socialHistory') },
    { text: t('occupation'), icon: <WorkIcon sx={{ color: '#FFFFFF' }} />, description: patient?.socialHistory?.occupation ?? '' },
    { text: t('maritalStatus'), icon: <FavoriteIcon sx={{ color: '#FFFFFF' }} />, description: patient?.socialHistory?.maritalStatus ?? '' },
    { text: t('housingStatus'), icon: <HouseIcon sx={{ color: '#FFFFFF' }} />, description: patient?.socialHistory?.housingStatus ?? '' },
    { text: t('financialStatus'), icon: <AccountBalanceIcon sx={{ color: '#FFFFFF' }} />, description: patient?.socialHistory?.financialStatus ?? '' },
    { text: t('supportSystem'), icon: <SupportIcon sx={{ color: '#FFFFFF' }} />, description: patient?.socialHistory?.supportSystem ?? '' },
    { type: 'header', title: t('lifestyle') },
    { text: t('smokingStatus'), icon: <SmokingRoomsIcon sx={{ color: '#FFFFFF' }} />, description: patient?.lifestyle?.smokingStatus ?? '' },
    { text: t('alcoholConsumption'), icon: <WineBarIcon sx={{ color: '#FFFFFF' }} />, description: patient?.lifestyle?.alcoholConsumption ?? '' },
    { text: t('exerciseFrequency'), icon: <SportsFootballIcon sx={{ color: '#FFFFFF' }} />, description: patient?.lifestyle?.exerciseFrequency ?? '' },
    { text: t('diet'), icon: <LocalDiningIcon sx={{ color: '#FFFFFF' }} />, description: patient?.lifestyle?.diet ?? '' },
    { type: 'header', title: t('extraInformation') },
    { text: t('languagePreference'), icon: <LanguageIcon sx={{ color: '#FFFFFF' }} />, description: patient?.languagePreference ?? '' },
    { text: t('primaryDoctor'), icon: <LocalHospitalIcon sx={{ color: '#FFFFFF' }} />, description: doctorName ?? '' },
    { text: t('advancedDirectives'), icon: <AnnouncementIcon sx={{ color: '#FFFFFF' }} />, description: patient?.advancedDirectives?.join(', ') ?? '' },
    { text: t('notes'), icon: <NotesIcon sx={{ color: '#FFFFFF' }} />, description: patient?.notes ?? '' },
  ];

  const fuse = new Fuse(listItems, {
    keys: ['title', 'text', 'description'],
    threshold: 0.2,  // Adjust the threshold to control the fuzziness
  });

  const filteredItems = searchQuery ? fuse.search(searchQuery).map(result => result.item) : listItems;

  return (
    <Box
      sx={{
        overflow: 'auto',
        width: '95%',
        marginTop: '0vh',
        "&::-webkit-scrollbar": { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Opera
        "-ms-overflow-style": 'none', // Hide scrollbar for IE and Edge
        "scrollbar-width": 'none', // Hide scrollbar for Firefox
      }}
    >
      <List dense sx={{ overflow: 'hidden' }}>
        {filteredItems.map((item, index) => (
          <ListItem
            button
            key={index}
            sx={{
              overflow: 'hidden', // Ensure no scrollbar appears on ListItem
              "&::-webkit-scrollbar": { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Opera
              "-ms-overflow-style": 'none', // Hide scrollbar for IE and Edge
              "scrollbar-width": 'none', // Hide scrollbar for Firefox
            }}
          >
            {item.icon && <ListItemIcon sx={{ color: '#C2C5C8' }}>{item.icon}</ListItemIcon>}
            {item.text && <ListItemText sx={{ color: '#C2C5C8' }} primary={item.text} />}
            {item.description && (
              <ListItemText
                sx={{ color: '#C2C5C8', textAlign: 'right' }}
                secondary={item.description}
              />
            )}
            {item.type === 'header' && (
              <ListItemText
                sx={{
                  marginTop: '2vh',
                  marginBottom: '2vh',
                  textAlign: 'left',
                  color: '#C2C5C8',
                }}
                primary={item.title}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default HealthDataList;
