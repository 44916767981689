import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux
import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, setErroredField, removeErroredField, setSectionSelected } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setFirstName, setLastName, setGender, setBirthDate, setAge } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const PatientInfo: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);

    const sectionSelected = useAppSelector((state) => state.createPatient.sectionSelected)

    const firstName = useAppSelector((state) => state.createPatient.firstName); 
    const lastName = useAppSelector((state) => state.createPatient.lastName); 
    const gender = useAppSelector((state) => state.createPatient.gender); 
    const age = useAppSelector((state) => state.createPatient.age); 
    const birthDate = useAppSelector((state) => state.createPatient.birthDate); 

    const [firstNameError, setFirstNameError] = useState(false); 
    const [lastNameError, setLastNameError] = useState(false); 
    const [genderError, setGenderError] = useState(false); 
    const [ageError, setAgeError] = useState(false); 
    const [birthDateError, setBirthDateError] = useState(false);


    // Handle input and error validation
    const handleInput = (input: string, field: string) => { 

        if (field === 'firstName') { 
            dispatch(setFirstName(input)); 
        } else if (field === 'lastName') { 
            dispatch(setLastName(input)); 
        } else if (field === 'age') { 
            dispatch(setAge(input)); 
        } else if (field === 'gender') { 
            dispatch(setGender(input)); 
        } else if (field === 'birthDate') { 
            dispatch(setBirthDate(input)); 
        };
    };

    // --

    useEffect(() => { 

        if (firstName && lastName && gender && age && birthDate) { 

            if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            }; 

            dispatch(setCompletedCategory(section)); 

        } else if (firstName || lastName || gender || age || birthDate) { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 

            }; 

            dispatch(setIncompletedCategory(section));

        } else { 

            if (completedCategories.includes(section)) { 

                dispatch(removeCompletedCategory(section)); 
                
            } else if (incompletedCategories.includes(section)) { 

                dispatch(removeIncompletedCategory(section)); 

            };
            
        };

    },[firstName, lastName, gender, age, birthDate]); 
    // -- 

    useEffect(() => { 

        erroredFields?.includes('firstName') ? setFirstNameError(true) : setFirstNameError(false); 
        erroredFields?.includes('lastName') ? setLastNameError(true) : setLastNameError(false);
        erroredFields?.includes('gender') ? setGenderError(true) : setGenderError(false);
        erroredFields?.includes('age') ? setAgeError(true) : setAgeError(false);
        erroredFields?.includes('birthDate') ? setBirthDateError(true) : setBirthDateError(false);

    },[erroredFields]); 

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '95%',
        }}>
            <TextField
                sx={{ width: '100%' }}
                label={t('firstname')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={firstName}
                onChange={(e) => handleInput(e.target.value, 'firstName')}
                fullWidth
                error={firstNameError}
                helperText={firstNameError ? t('This field is required') : ''}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}
                label={t('lastname')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={lastName}
                onChange={(e) => handleInput(e.target.value, 'lastName')}
                fullWidth
                error={lastNameError}
                helperText={lastNameError ? t('This field is required') : ''}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('gender')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={gender}
                onChange={(e) => handleInput(e.target.value, 'gender')}
                fullWidth
                error={genderError}
                helperText={genderError ? t('This field is required') : ''}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('dateOfBirth')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={birthDate}
                onChange={(e) => handleInput(e.target.value, 'birthDate')}
                fullWidth
                error={birthDateError}
                helperText={birthDateError ? t('This field is required') : ''}
                variant="outlined"
            />

            <TextField
                sx={{ width: '100%' }}            
                label={t('age')}
                InputProps={{ startAdornment: <AccountCircleIcon /> }}
                value={age}
                onChange={(e) => handleInput(e.target.value, 'age')}
                fullWidth
                error={ageError}
                helperText={ageError ? t('This field is required') : ''}
                variant="outlined"
            />
        </Box>
    );
};

export default PatientInfo;
