import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  onSearch: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      height: '10%', 
      borderRadius: 5,
      backgroundColor: '#313338',
    }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        width: '95%',
        height: '70%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
      }}>
        <SearchIcon sx={{ fontSize: 15, marginLeft: 2, color: 'grey' }}/>
        <TextField
          id="standard-search"
          placeholder={t('search') || 'Search'}
          type="search"
          variant="standard"
          value={query}
          onChange={handleSearchChange}
          sx={{
            marginLeft: 2,
            width: '80%',
            borderBottomColor: 'grey'
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
