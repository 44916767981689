import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Redux 

import { resetFileExplorer, openFileExplorer } from '../../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { getSuggestedCorrections } from '../../../../../../../routes/doctor/files';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { setCorrections, setIsCorrectionsOpened, setSelectedFile } from '../../../../../../../redux/features/doctor/Dashboard/documents/correctFile';


const SelectFile: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const fileToCorrect = useAppSelector(state => state.correctFile.selectedFile); 

    // -- 

    const handleFileSelection = () => { 

        dispatch(openFileExplorer()); 

    }; 

    // -- 

    const handleFileCorrection = async () => { 

        const res = await getSuggestedCorrections(fileToCorrect._id) as any; 

        if (res.data.status === '200') { 

            dispatch(setCorrections(res.data.corrections)); 

             dispatch(setNotificationMessage(t('feedbackReceived')));

        }; 


    }; 

    // -- 

    useEffect(() => { 

        if (selectedFile) { 

            dispatch(setSelectedFile(selectedFile)); 

            dispatch(resetFileExplorer()); 

        }; 

    },[selectedFile]);

    // -- 

    return (

        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            }}
        >

        { fileToCorrect ? 
        <Button onClick={() => handleFileCorrection()} variant="contained">{t('correctFile')}</Button>: 
        <Button onClick={() => handleFileSelection()} variant="contained">{t('selectFile')}</Button>}

  
        </Box> 


    );
}; 
  

export default SelectFile;
