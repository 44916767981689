import React, { useState, useRef, useEffect } from 'react';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import * as docx from 'docx-preview';

// Redux

import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Utils 

import { setPatientSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setPatientFileData } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setPatientFileExtension } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setPatientFileName } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

import SearchBar from './SearchBar';
import HealthInfo from './HealthInfo';
import PatientDocs from './documents/PatientDocs';

type Props = {
    isPortraitMode: boolean,
};

const PatientForm: React.FC<Props> = ({ isPortraitMode }) => {

    const dispatch = useAppDispatch(); 

    const [searchQuery, setSearchQuery] = useState('');

    
    const handleFileResponse = (data: string, extension: string, name: string) => {
        dispatch(setPatientFileData(data));
        dispatch(setPatientFileExtension(extension));
        dispatch(setPatientFileName(name)); 
        dispatch(setPatientSection(('file')));
    };

      return <>
      
      <SearchBar onSearch={setSearchQuery} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: isPortraitMode ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '85%',
          overflow: 'hidden',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          padding: '10px 0',
        }}
      >
        <HealthInfo searchQuery={searchQuery} />
        <PatientDocs searchQuery={searchQuery} onSuccess={handleFileResponse} />
      </Box>
      </>
};

export default PatientForm;