import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import medcaLogo from '../assets/logos/medca_logo_blue_white.png';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';

const Tos: React.FC = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!ready) {
    return <div>Loading...</div>;
  }

  const tosContent = t('tosContent', { returnObjects: true }) as any;

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        mt: 4,
        mb: 4,
        color: '#fff',
        backgroundColor: '#333',
        padding: '40px',
        borderRadius: '8px',
      }}
    >
      <Box position="absolute" top={16} left={16}>
        <Link
          component="button"
          variant="body2"
          onClick={handleGoBack}
          sx={{
            color: 'gray',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <ArrowBackIosIcon fontSize="small" sx={{ mr: 0.5 }} />
          {t('goBack')}
        </Link>
      </Box>
      <Box position="absolute" top={16} right={16}>
        <LanguageSelector />
      </Box>
      <Box position="absolute" top={16} right={150}>
        <ThemeToggle />
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Logo size={30} />
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          {t('termsOfService')}
        </Typography>
        <Box sx={{ textAlign: 'justify', width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.acceptanceOfTerms?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.acceptanceOfTerms?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.platformUsage?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {tosContent?.platformUsage?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.platformUsage?.usage1 && <li>{tosContent.platformUsage.usage1}</li>}
              {tosContent?.platformUsage?.usage2 && <li>{tosContent.platformUsage.usage2}</li>}
            </ul>
          </Box>

          <br></br>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.accountManagement?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {tosContent?.accountManagement?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.accountManagement?.management1 && <li>{tosContent.accountManagement.management1}</li>}
              {tosContent?.accountManagement?.management2 && <li>{tosContent.accountManagement.management2}</li>}
              {tosContent?.accountManagement?.management3 && <li>{tosContent.accountManagement.management3}</li>}
            </ul>
          </Box>

          <br></br>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.dataProtection?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.dataProtection?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.serviceAvailability?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.serviceAvailability?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.limitationOfLiability?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.limitationOfLiability?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.governingLaw?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.governingLaw?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.userConduct?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {tosContent?.userConduct?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.userConduct?.conduct1 && <li>{tosContent.userConduct.conduct1}</li>}
              {tosContent?.userConduct?.conduct2 && <li>{tosContent.userConduct.conduct2}</li>}
              {tosContent?.userConduct?.conduct3 && <li>{tosContent.userConduct.conduct3}</li>}
            </ul>
          </Box>

          <br></br>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.intellectualProperty?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.intellectualProperty?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.termination?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.termination?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.modificationOfTerms?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.modificationOfTerms?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.disputeResolution?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.disputeResolution?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.disclaimerOfWarranties?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.disclaimerOfWarranties?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {tosContent?.contactInformation?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {tosContent?.contactInformation?.description}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Tos;