import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UploadFileState {
  file: File | null;
  title: string;
  description: string;
}

const initialState: UploadFileState = {
  file: null,
  title: '',
  description: '',
};

const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<File>) => {
      state.file = action.payload;
    },
    clearFile: (state) => {
      state.file = null;
      state.title = '';
      state.description = '';
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setResetUploadFile: (state) => { 
        
      state = initialState; 

    } 
  },
});

export const { setFile, clearFile, setTitle, setDescription, setResetUploadFile } = uploadFileSlice.actions;

export default uploadFileSlice.reducer;