import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 
import { clearFile } from './../../../../../redux/features/doctor/Dashboard/documents/uploadFileSlice';

// Components 

import SubSideBar from '../../../../general/navigation/SubSideBar';

const MenuList: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isSmallScreen = useMediaQuery('(max-width: 930px)'); // Matches the Sidebar and Menu breakpoint

    const subSection = useAppSelector((state) => state.navigation.subSection); // Test

    useEffect(() => { 

        dispatch(clearFile()); 

    },[subSection]); 

    return (
        <Box sx={{ 
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
        }}>
            <SubSideBar />
        </Box>
    );
};

export default MenuList;
