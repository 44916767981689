import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { getSupportTickets } from '../../../routes/hacker/users';
import { getTicketDetails } from '../../../routes/hacker/users';

// Redux 

import { setSupportTicket } from '../../../redux/features/support/supportTicket';
import { setOpenPopup } from '../../../redux/features/general/popup';
import { setAuthorPersonalInfo } from '../../../redux/features/support/supportTicket';

// Components 

import SupportTicketHeader from './SupportTicketHeader';
import SupportTicketRow from './SupportTicketRow';
import Popup from '../../general/Popup';
import TicketDetails from './TicketDetails';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const SupportTicketsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    const [supportTickets, setSupportTickets] = useState(Array<any>);

    // -- 

    const getSupportTicketsList = async () => { 

      const supportTicketsList = await getSupportTickets() as any; 

      if (supportTicketsList) { 

        setSupportTickets(supportTicketsList); 

      };

    }; 

    // -- 

    const selectSupportTicket = (supportTicket: any) => { 

      dispatch(setSupportTicket(supportTicket)); 

      dispatch(setOpenPopup()); 

      getAdditionalInfo(supportTicket.author); 

    }; 

    // --

    const getAdditionalInfo = async (author: string) => { 

      const content = await getTicketDetails(author); 

      dispatch(setAuthorPersonalInfo(content)); 

  }; 

    // -- 

    useEffect(() => { 

        getSupportTicketsList(); 

    },[]); 
    

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '90vw',
              backgroundColor: '#383A40',
            }}>

            <SupportTicketHeader />

            { supportTickets?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                height: '82.5' }}>
        
                <List dense>
        
                {supportTickets?.map((item, index) => (
        
                    <ListItem onClick={() => selectSupportTicket(item)} button key={index}>

                        <SupportTicketRow
                            author={item.author} 
                            ticketId={item._id}
                            status={item.status} 
                            category={item.category}
                            description={item.description}
                            createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {supportTickets?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>No Support Ticket detected.</div>

          </Box>}

            </Box>

            { isPopupOpened && 
              <Popup>
                <TicketDetails />
              </Popup>}

        </Box>
    
      );

};

export default SupportTicketsList;