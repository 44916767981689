import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';
import Corrections from './formItems/Corrections';
import SelectFile from './formItems/SelectFile';


const CorrectFile: React.FC = () => {

  const isCorrectionsOpened = useAppSelector(state => state.correctFile.isCorrectionsOpened); 

  return ( 

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '95%',
      height: '95%', 
      borderRadius: 5,
      backgroundColor: '#383A40',
    }}>

      { !isCorrectionsOpened && <SelectFile />}

      { isCorrectionsOpened && <Corrections />} 


    </Box>
  )


};

export default CorrectFile;