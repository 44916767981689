import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    isFileExplorerOpened: boolean,
    refreshFileExplorer: boolean,  
    searchQuery: string, 
    searchSection: string,
    currentFolder: any, 
    directory: string,
    unconfirmedSelection: any, 
    selectedFile: any,
    folderData: any[],
}

const initialState: InitialState = {
    isFileExplorerOpened: false,
    refreshFileExplorer: false, 
    searchQuery: '', 
    searchSection: 'recent',
    currentFolder: null, 
    directory: '', 
    unconfirmedSelection: null, 
    selectedFile: null,
    folderData: [],
}

export const fileExplorerSlice = createSlice({
  name: 'fileExplorer',
  initialState,
    reducers: {

    openFileExplorer: (state) => {
        state.isFileExplorerOpened = true
    },
    setRefreshFileExplorer: (state, action: PayloadAction<boolean>) => { 
      state.refreshFileExplorer = action.payload; 
    },  
    setSearchQuery: (state, action: PayloadAction<string>) => { 
      state.searchQuery = action.payload; 
    },  
    setSearchSection: (state, action: PayloadAction<string>) => {
        state.searchSection = action.payload
    },
    setCurrentFolder: (state, action: PayloadAction<any>) => {
      state.currentFolder = action.payload
      state.directory = action.payload ? action.payload.path : ''; 

    },
    setDirectory: (state, action: PayloadAction<string>) => {
        state.directory = action.payload;  
    },
    setUnconfirmedSelection: (state, action: PayloadAction<any>) => {
      state.unconfirmedSelection = action.payload
    },
    selectFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload
    },
    setFolderData: (state, action: PayloadAction<any>) => { 
      state.folderData = action.payload
    }, 
    resetFileExplorer: (state) => { 
        
        state.isFileExplorerOpened = false; 
        state.refreshFileExplorer = false; 
        state.searchQuery = ''; 
        state.searchSection = 'recent';
        state.currentFolder = null; 
        state.directory = '';
        state.unconfirmedSelection = null; 
        state.selectedFile = null;
        state.folderData = [];

    } 
  },
});

export const {
    openFileExplorer,
    setRefreshFileExplorer, 
    setSearchQuery, 
    setSearchSection,
    setCurrentFolder, 
    setDirectory,
    setUnconfirmedSelection, 
    selectFile,
    setFolderData,
    resetFileExplorer,
} = fileExplorerSlice.actions;

export default fileExplorerSlice.reducer;