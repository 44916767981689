import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { getMetaPatientInfo } from '../../../../routes/doctor/dashboard';

type Props = {
    patientId: string,
    selectedPatientId?: string | null
};


const PatientsListRow: React.FC<Props> = ({ patientId, selectedPatientId }) => {

    const dispatch = useAppDispatch(); 

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handlePatientInfo = async () => { 

        const res = await getMetaPatientInfo(patientId); 

        if (res?.data.status == '200'){

            setFirstName(res.data.firstName); 
            setLastName(res.data.lastName); 
        }

    } 

    useEffect(() => { 

        handlePatientInfo(); 
        
    },[]); 

  return (

    <Box>
             <ListItemIcon sx={{ color: selectedPatientId === patientId ? '#FFFFFF' : '#C2C5C8' }}>
                <PersonIcon sx={{ color: selectedPatientId === patientId ? '#FFFFFF' : '#C2C5C8' }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: selectedPatientId === patientId ? '#FFFFFF' : '#C2C5C8' }}
                primary={`${firstName} ${lastName}`}
              />

    </Box>

  );

};

export default PatientsListRow;