const titleExamples = [

    { title: 'Medical Form A'},
    { title: 'Medical Form B'},
    { title: 'Medical Form C'},
    { title: 'Medical Form D'},
    { title: 'Medical Form E'},
    { title: 'Medical Form F'},
    { title: 'Medical Form G'},
    { title: 'Medical Form H'},
    { title: 'Medical Form I'},
    { title: 'Medical Form K'},
    { title: 'Medical Form J'},
    { title: 'Medical Form L'},
    { title: 'Medical Form I'},
    { title: 'Medical Form K'},
    { title: 'Medical Form J'},
    { title: 'Medical Form L'},

]; 

export default titleExamples; 