import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Icons 

import Icon from '@mui/material/Icon';

// Redux 

import { setDashboardSection } from './../../../redux/features/general/navigation'; 


const Sidebar: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const drawerWidth = '10vw';

    const section = useAppSelector(state => state.navigation.section);
    const sideBarMenu = useAppSelector(state => state.navigation.sideBarMenu);

    // To change section when clicking on a menu item. 

    const changeSection = (section: any) => { 

        if (section) { 

            dispatch(setDashboardSection(section)); 

        };

    }; 

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        height: '95vh',
        flexShrink: 0,
        ['& .MuiDrawer-paper']: { width: drawerWidth, height: '95vh', marginTop: '5vh', boxSizing: 'border-box', backgroundColor: '#2B2D31', color: '#FFFFFF' },
      }}
    >
      <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>

        <List dense>

          {sideBarMenu?.map((item, index) => (

            <ListItem sx={{
                cursor: item.type === 'header' ? 'auto' : 'pointer',
                backgroundColor: section === item.section ? '#4D4B59' : 'transparent',
                '&:hover': { backgroundColor: item.type === 'header' ? 'transparent' : '#4D4B59' }
            }}
            onClick={() => changeSection(item.section)} button key={index}>


                { item.type === 'item' && 
                <ListItemIcon sx={{ color: item.iconColor }}>
                  <Icon>{item.iconName}</Icon>
                </ListItemIcon>}

                { item.type === 'item' && <ListItemText sx={{ color: '#C2C5C8' }} primary={t(item.text)} />}

                { item.type === 'header' && 
                    <ListItemText 
                        sx={{ marginTop: '2vh',
                            color: '#C2C5C8' }} 
                            primary={t(item.text)} 
                    />}

            </ListItem>
          ))}

        </List>

      </Box>
    </Drawer>
  );
};

export default Sidebar;