import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import moment from 'moment';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';

type Props = {
    _id?: string,
    title: string,
    fileUrl: string,
    description: string,
    uploadedBy: string,
    sentTo: string,
    relatedPatient: string,
    createdAt?: string,
    updatedAt?: string,
    type?: string,
    searchQuery?: boolean, 
};


const FolderDataRow: React.FC<Props> = ({ _id, title, fileUrl, description, uploadedBy, sentTo, relatedPatient, createdAt, updatedAt, type, searchQuery }) => {

    const dispatch = useAppDispatch(); 

    const unconfirmedSelection = useAppSelector((state) => state.fileExplorer.unconfirmedSelection); 

    return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        width: '100%',
        fontSize: 12, 
        height: 50,
        backgroundColor: unconfirmedSelection?._id === _id ? 'rgba(0, 255, 238, 0.1)' : 'transparent'
        }}>
        
    
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '10%', 
                marginLeft: '2%',  
            }}>

                { (type === 'file' && !searchQuery) && <InsertDriveFileIcon sx={{ color: 'rgb(255,185,0)', fontSize: 15, marginLeft: 1 }} />}
                { (type === 'folder' && !searchQuery) && <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 1 }} />}

                { (type === 'file' && searchQuery) && <InsertDriveFileIcon sx={{ color: 'rgb(13, 186, 13)', fontSize: 15, marginLeft: 1 }} />}
                { (type === 'folder' && searchQuery) && <FolderIcon sx={{ color: 'rgb(9, 145, 9)', fontSize: 15, marginLeft: 1 }} />}
                
            </div>


            <div style={{ 
                width: '30%',
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{title}</div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
                marginLeft: '1%' 
            }}>{type === 'file' ? 'File': 'Folder'}</div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{moment.utc(createdAt).format('L')}</div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{moment.utc(updatedAt).format('L')}</div>
    
        </Box>

    );

};

export default FolderDataRow;