import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components 

import HeaderSection from './HeaderSection';
import FilesList from './FilesList';
import ListInfo from './filesList/ListInfo';
import ConfirmButton from './ConfirmButton';
import SelectedFileInfo from './SelectedFileInfo';

type Props = {
    children?: React.ReactNode;
};


const FilesSection: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '85%',
            height: '100%', 
        }}>

            <HeaderSection />

            <FilesList />

            <ListInfo />

            <SelectedFileInfo />

            <ConfirmButton />
            
        </Box>

    );

};

export default FilesSection;