import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Notification from '../../../../general/Notification';

// Components 

import NavigationBar from './NavigationBar';
import CategoryTitle from './CategoryTitle';

import PatientInfo from './categories/PatientInfo';
import ContactInfo from './categories/ContactInfo';
import EmergencyContact from './categories/EmergencyContact';
import HealthInfo from './categories/HealthInfo';
import MedicalHistory from './categories/MedicalHistory';
import InsuranceDetails from './categories/InsuranceDetails';
import SocialHistory from './categories/SocialHistory';
import Lifestyle from './categories/Lifestyle';
import ExtraInformation from './categories/ExtraInformation';
import Confirmation from './Confirmation';


import Popup from '../../../../general/Popup';
import ExitProcess from './ExitProcess';

const CreatePatientForm: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const section = useAppSelector((state) => state.createPatient.section); 
  const completedCategories = useAppSelector((state) => state.createPatient.completedCategories); 
  const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

  // -- 

  window.onbeforeunload = function (e: any) {

    if (completedCategories.length > 0) { 

      // For IE and Firefox

      if (e) {
        e.returnValue;
      }; 
    
      // For Safari
      return e.returnValue;

    };

  };

  // -- 

  return (

    <Box
      sx={{
        width: '100%',
        padding: '2rem',
        "& .MuiTextField-root": { marginBottom: '1rem' },
        "& .MuiFormControl-root": { marginBottom: '1rem' },
        maxHeight: '80vh',
        overflow: 'auto',
        scrollbarWidth: 'none',
      }}
    >

      <CategoryTitle />

      { section === 'patientInfo' && <PatientInfo />}

      { section === 'contactInfo' && <ContactInfo />}

      { section === 'emergencyContact' && <EmergencyContact />}

      { section === 'healthInformation' && <HealthInfo />}

      { section === 'medicalHistory' && <MedicalHistory />}

      { section === 'insuranceDetails' && <InsuranceDetails />}

      { section === 'socialHistory' && <SocialHistory />}

      { section === 'lifestyle' && <Lifestyle />}

      { section === 'extraInformation' && <ExtraInformation />}

      { section === 'confirmation' && <Confirmation />}

      <NavigationBar />

      { isPopupOpened && 
        <Popup>
          <ExitProcess />
        </Popup>}

      <Notification />

    </Box>
  );
};

export default CreatePatientForm;









// Soviet Code (from the old system); 


// Interface 

// interface Surgery {
//   type: string;
//   date: Date;
//   hospital: string;
// }

// interface FamilyMedicalHistory {
//   condition: string;
//   relation: string;
// }

// interface Immunizations {
//   name: string;
//   date: Date;
// }

// interface FormValues {
//   firstName: string;
//   lastName: string;
//   dateOfBirth: string;
//   gender: string;

//   healthCardNumber: string;
//   medicalHistory: string;
//   phone: string;
//   email: string;
//   address: string;

//   emergencyContactName: string;
//   emergencyContactRelation: string;
//   emergencyContactPhone: string;


//   currentMedications: string[];
//   familyMedicalHistory: FamilyMedicalHistory[];
//   mentalHealthHistory: string[];
//   allergies: string[];
//   bloodType: string;
//   knownConditions: string[];
//   surgeries: Surgery[];
//   immunizations: Immunizations[];
//   geneticDisorders: string[];
//   disabilities: string[];

//   provider: string;
//   policyNumber: string;
//   groupNumber: string;

//   occupation: string;
//   maritalStatus: string;
//   housingStatus: string;
//   financialStatus: string;
//   supportSystem: string;

//   smokingStatus: string;
//   alcoholConsumption: string;
//   exerciseFrequency: string;
//   diet: string;

//   languagePreference: string;
//   primaryDoctor: string;
//   advancedDirectives: string[];
//   notes: string;
// }

// Functions 


// firstName: '',
// lastName: '',
// dateOfBirth: '',
// gender: '',
// healthCardNumber: '',
// medicalHistory: '',
// phone: '',
// email: '',
// address: '',
// emergencyContactName: '',
// emergencyContactRelation: '',
// emergencyContactPhone: '',
// currentMedications: [],
// familyMedicalHistory: [],
// mentalHealthHistory: [],
// allergies: [],
// bloodType: '',
// knownConditions: [],
// surgeries: [],
// immunizations: [],
// geneticDisorders: [],
// disabilities: [],
// provider: '',
// policyNumber: '',
// groupNumber: '',
// occupation: '',
// maritalStatus: '',
// housingStatus: '',
// financialStatus: '',
// supportSystem: '',
// smokingStatus: '',
// alcoholConsumption: '',
// exerciseFrequency: '',
// diet: '',
// languagePreference: '',
// primaryDoctor: '',
// advancedDirectives: [],
// notes: '',
// };

// const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

// const patients = useAppSelector((state) => state.patientList.patients);
// const [patientsList, setPatientsList] = useState(Array<any>);

// useEffect(() => {
// setPatientsList(patients);
// }, [patients]);

// const handleChange = (field: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
// setFormValues((prev) => ({
//   ...prev,
//   [field]: event.target.value,
// }));
// };

// const handleSurgeryChange = (index: number, field: keyof Surgery) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newSurgeries = [...formValues.surgeries];
// newSurgeries[index] = {
//   ...newSurgeries[index],
//   [field]: field === 'date' ? new Date(event.target.value) : event.target.value,
// };
// setFormValues((prev) => ({
//   ...prev,
//   surgeries: newSurgeries,
// }));
// };

// const addSurgery = () => {
// setFormValues((prev) => ({
//   ...prev,
//   surgeries: [...prev.surgeries, { type: '', date: new Date(), hospital: '' }],
// }));
// };

// const handleFamilyHistoryChange = (index: number, field: keyof FamilyMedicalHistory) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newFamilyHistory = [...formValues.familyMedicalHistory];
// newFamilyHistory[index] = {
// ...newFamilyHistory[index],
// [field]: event.target.value,
// };
// setFormValues((prev) => ({
// ...prev,
// familyMedicalHistory: newFamilyHistory,
// }));
// };

// const addFamilyHistory = () => {
// setFormValues((prev) => ({
// ...prev,
// familyMedicalHistory: [...prev.familyMedicalHistory, { condition: '', relation: '' }],
// }));
// };

// const handleImmunizationChange = (index: number, field: keyof Immunizations) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newImmunizations = [...formValues.immunizations];
// newImmunizations[index] = {
// ...newImmunizations[index],
// [field]: field === 'date' ? new Date(event.target.value) : event.target.value,
// };
// setFormValues((prev) => ({
// ...prev,
// immunizations: newImmunizations,
// }));
// };

// const addImmunization = () => {
// setFormValues((prev) => ({
// ...prev,
// immunizations: [...prev.immunizations, { name: '', date: new Date() }],
// }));
// };

// const handleCurrentMedicationChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newCurrentMedications = [...formValues.currentMedications];
// newCurrentMedications[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// currentMedications: newCurrentMedications,
// }));
// };

// const addCurrentMedication = () => {
// setFormValues((prev) => ({
// ...prev,
// currentMedications: [...prev.currentMedications, ''],
// }));
// };

// const handleMentalHealthHistoryChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newMentalHealthHistory = [...formValues.mentalHealthHistory];
// newMentalHealthHistory[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// mentalHealthHistory: newMentalHealthHistory,
// }));
// };

// const addMentalHealthHistory = () => {
// setFormValues((prev) => ({
// ...prev,
// mentalHealthHistory: [...prev.mentalHealthHistory, ''],
// }));
// };

// const handleAllergyChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newAllergies = [...formValues.allergies];
// newAllergies[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// allergies: newAllergies,
// }));
// };

// const addAllergy = () => {
// setFormValues((prev) => ({
// ...prev,
// allergies: [...prev.allergies, ''],
// }));
// };

// const handleKnownConditionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newKnownConditions = [...formValues.knownConditions];
// newKnownConditions[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// knownConditions: newKnownConditions,
// }));
// };

// const addKnownCondition = () => {
// setFormValues((prev) => ({
// ...prev,
// knownConditions: [...prev.knownConditions, ''],
// }));
// };

// const handleGeneticDisorderChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newGeneticDisorders = [...formValues.geneticDisorders];
// newGeneticDisorders[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// geneticDisorders: newGeneticDisorders,
// }));
// };

// const addGeneticDisorder = () => {
// setFormValues((prev) => ({
// ...prev,
// geneticDisorders: [...prev.geneticDisorders, ''],
// }));
// };

// const handleDisabilityChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newDisabilities = [...formValues.disabilities];
// newDisabilities[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// disabilities: newDisabilities,
// }));
// };

// const addDisability = () => {
// setFormValues((prev) => ({
// ...prev,
// disabilities: [...prev.disabilities, ''],
// }));
// };

// const handleAdvancedDirectiveChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
// const newAdvancedDirectives = [...formValues.advancedDirectives];
// newAdvancedDirectives[index] = event.target.value;
// setFormValues((prev) => ({
// ...prev,
// advancedDirectives: newAdvancedDirectives,
// }));
// };

// const addAdvancedDirectives = () => {
// setFormValues((prev) => ({
// ...prev,
// advancedDirectives: [...prev.advancedDirectives, ''],
// }));
// };

// const handleSubmit = async () => {
// try {
// const token = Cookies.get('jwt');

// if (!token) {
//   throw new Error('JWT token is missing');
// }

// const res = await axios.post(
//   `${process.env.REACT_APP_BACKEND_URL}/doctor/doctor/createPatient`,
//   {
//     formValues: formValues
//   },
//   {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
// );

// dispatch(setNotificationMessage(t('createPatientOK')));
// dispatch(addPatient(res.data.newPatient));
// dispatch(setPatient(res.data.newPatient._id))
// dispatch(setSelectedPatient(res.data.newPatient._id))
// dispatch(setPatientSection(('form')));
// // Reset the form values to the initial state
// setFormValues(initialFormValues);
// } catch (error) {
// dispatch(setNotificationMessage(t('createPatientNotOK')));
// }
// };

// const displayAge = (dateOfBirth: string) => {
// if (!dateOfBirth) return '';
// const birthDate = moment.utc(dateOfBirth);
// const today = moment();
// const age = today.diff(birthDate, 'years');
// return `${age} ${t('years')}`;
// };








// Icons


// import CakeIcon from '@mui/icons-material/Cake';
// import WcIcon from '@mui/icons-material/Wc';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
// import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import EmailIcon from '@mui/icons-material/Email';
// import HomeIcon from '@mui/icons-material/Home';
// import PersonIcon from '@mui/icons-material/Person';
// import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
// import MedicationIcon from '@mui/icons-material/Medication';
// import PsychologyIcon from '@mui/icons-material/Psychology';
// import NoFoodIcon from '@mui/icons-material/NoFood';
// import BloodtypeIcon from '@mui/icons-material/Bloodtype';
// import SickIcon from '@mui/icons-material/Sick';
// import MasksIcon from '@mui/icons-material/Masks';
// import VaccinesIcon from '@mui/icons-material/Vaccines';
// import Diversity2Icon from '@mui/icons-material/Diversity2';
// import AccessibleIcon from '@mui/icons-material/Accessible';
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import NumbersIcon from '@mui/icons-material/Numbers';
// import WorkIcon from '@mui/icons-material/Work';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import HouseIcon from '@mui/icons-material/House';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import SupportIcon from '@mui/icons-material/Support';
// import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
// import WineBarIcon from '@mui/icons-material/WineBar';
// import SportsFootballIcon from '@mui/icons-material/SportsFootball';
// import LocalDiningIcon from '@mui/icons-material/LocalDining';
// import LanguageIcon from '@mui/icons-material/Language';
// import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
// import AnnouncementIcon from '@mui/icons-material/Announcement';
// import NotesIcon from '@mui/icons-material/Notes';



// Soviet Code 

      {/* <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2>{t('patientInfo')}</h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('firstname')}
              InputProps={{ startAdornment: <CakeIcon /> }}
              value={formValues.firstName}
              onChange={handleChange('firstName')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('lastname')}
              InputProps={{ startAdornment: <CakeIcon /> }}
              value={formValues.lastName}
              onChange={handleChange('lastName')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="date"
              value={moment(formValues.dateOfBirth).format('YYYY-MM-DD')}
              onChange={handleChange('dateOfBirth')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('gender')}
              InputProps={{ startAdornment: <WcIcon /> }}
              value={formValues.gender}
              onChange={handleChange('gender')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                label={t('age')}
                InputProps={{ startAdornment: <CalendarMonthIcon />, readOnly: true }}
                value={displayAge(formValues.dateOfBirth)}
                fullWidth
                variant="outlined"
              />
            </Grid>




          <Grid item xs={12}>
            <h2>{t('contactInfo')}</h2>
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                label={t('healthCardNumber')}
                InputProps={{ startAdornment: <MedicalServicesIcon /> }}
                value={formValues.healthCardNumber}
                onChange={handleChange('healthCardNumber')}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('medicalHistory')}
                InputProps={{ startAdornment: <MedicalInformationIcon /> }}
                value={formValues.medicalHistory}
                onChange={handleChange('medicalHistory')}
                fullWidth
                variant="outlined"
              />
            </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('phone')}
              InputProps={{ startAdornment: <LocalPhoneIcon /> }}
              value={formValues.phone}
              onChange={handleChange('phone')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('email')}
              InputProps={{ startAdornment: <EmailIcon /> }}
              value={formValues.email}
              onChange={handleChange('email')}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('address')}
              InputProps={{ startAdornment: <HomeIcon /> }}
              value={formValues.address}
              onChange={handleChange('address')}
              fullWidth
              variant="outlined"
            />
          </Grid>




          <Grid item xs={12}>
              <h2>{t('emergencyContact')}</h2>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('name')}
                InputProps={{ startAdornment: <PersonIcon /> }}
                value={formValues.emergencyContactName}
                onChange={handleChange('emergencyContactName')}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('relation')}
                InputProps={{ startAdornment: <FamilyRestroomIcon /> }}
                value={formValues.emergencyContactRelation}
                onChange={handleChange('emergencyContactRelation')}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('emergencyContactPhone')}
                InputProps={{ startAdornment: <LocalPhoneIcon /> }}
                value={formValues.emergencyContactPhone}
                onChange={handleChange('emergencyContactPhone')}
                fullWidth
                variant="outlined"
              />
            </Grid>




            <Grid item xs={12}>
              <h2>{t('healthInformation')}</h2>
            </Grid>
            <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('medicationName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.currentMedications.map((medication, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={medication}
                  onChange={handleCurrentMedicationChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addCurrentMedication} variant="contained" color="primary">
        {t('addMedication')}
      </Button>
    </Grid>




          <Grid container spacing={2}></Grid>
          <Grid item xs={12}>
            <h2>{t('medicalHistory')}</h2>
          </Grid>
          <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('condition')}</TableCell>
            <TableCell>{t('relation')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.familyMedicalHistory.map((history, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={history.condition}
                  onChange={handleFamilyHistoryChange(index, 'condition')}
                  fullWidth
                  variant="outlined"
                /> 
              </TableCell>
              <TableCell>
                <TextField
                  value={history.relation}
                  onChange={handleFamilyHistoryChange(index, 'relation')}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addFamilyHistory} variant="contained" color="primary">
        {t('addFamilyHistory')}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('mentalHealthCondition')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.mentalHealthHistory.map((history, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={history}
                  onChange={handleMentalHealthHistoryChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addMentalHealthHistory} variant="contained" color="primary">
        {t('addMentalHealthCondition')}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('allergyName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.allergies.map((allergy, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={allergy}
                  onChange={handleAllergyChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addAllergy} variant="contained" color="primary">
        {t('addAllergy')}
      </Button>
    </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('bloodType')}
          InputProps={{ startAdornment: <BloodtypeIcon /> }}
          value={formValues.bloodType}
          onChange={handleChange('bloodType')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('knownConditionName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.knownConditions.map((condition, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={condition}
                  onChange={handleKnownConditionChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addKnownCondition} variant="contained" color="primary">
        {t('addKnownCondition')}
      </Button>
    </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('surgeryType')}</TableCell>
                  <TableCell>{t('surgeryDate')}</TableCell>
                  <TableCell>{t('surgeryHospital')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formValues.surgeries.map((surgery, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        value={surgery.type}
                        onChange={handleSurgeryChange(index, 'type')}
                        fullWidth
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        value={moment(surgery.date).format('YYYY-MM-DD')}
                        onChange={handleSurgeryChange(index, 'date')}
                        fullWidth
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={surgery.hospital}
                        onChange={handleSurgeryChange(index, 'hospital')}
                        fullWidth
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button onClick={addSurgery} variant="contained" color="primary">
              {t('addSurgery')}
            </Button>
          </Grid>
          <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('immunizationName')}</TableCell>
            <TableCell>{t('immunizationDate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.immunizations.map((immunization, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={immunization.name}
                  onChange={handleImmunizationChange(index, 'name')}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={moment(immunization.date).format('YYYY-MM-DD')}
                  onChange={handleImmunizationChange(index, 'date')}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addImmunization} variant="contained" color="primary">
        {t('addImmunization')}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('geneticDisorderName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.geneticDisorders.map((disorder, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={disorder}
                  onChange={handleGeneticDisorderChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addGeneticDisorder} variant="contained" color="primary">
        {t('addGeneticDisorder')}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('disabilityName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.disabilities.map((disability, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={disability}
                  onChange={handleDisabilityChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addDisability} variant="contained" color="primary">
        {t('addDisability')}
      </Button>
    </Grid>








      <Grid item xs={12}>
        <h2>{t('insuranceDetails')}</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('provider')}
          InputProps={{ startAdornment: <BusinessCenterIcon /> }}
          value={formValues.provider}
          onChange={handleChange('provider')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('policyNumber')}
          InputProps={{ startAdornment: <NumbersIcon /> }}
          value={formValues.policyNumber}
          onChange={handleChange('policyNumber')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('groupNumber')}
          InputProps={{ startAdornment: <NumbersIcon /> }}
          value={formValues.groupNumber}
          onChange={handleChange('groupNumber')}
          fullWidth
          variant="outlined"
        />
      </Grid>



      <Grid item xs={12}>
        <h2>{t('socialHistory')}</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('occupation')}
          InputProps={{ startAdornment: <WorkIcon /> }}
          value={formValues.occupation}
          onChange={handleChange('occupation')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('maritalStatus')}
          InputProps={{ startAdornment: <FavoriteIcon /> }}
          value={formValues.maritalStatus}
          onChange={handleChange('maritalStatus')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('housingStatus')}
          InputProps={{ startAdornment: <HouseIcon /> }}
          value={formValues.housingStatus}
          onChange={handleChange('housingStatus')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('financialStatus')}
          InputProps={{ startAdornment: <AccountBalanceIcon /> }}
          value={formValues.financialStatus}
          onChange={handleChange('financialStatus')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('supportSystem')}
          InputProps={{ startAdornment: <SupportIcon /> }}
          value={formValues.supportSystem}
          onChange={handleChange('supportSystem')}
          fullWidth
          variant="outlined"
        />
      </Grid>





      <Grid item xs={12}>
        <h2>{t('lifestyle')}</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('smokingStatus')}
          InputProps={{ startAdornment: <SmokingRoomsIcon /> }}
          value={formValues.smokingStatus}
          onChange={handleChange('smokingStatus')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('alcoholConsumption')}
          InputProps={{ startAdornment: <WineBarIcon /> }}
          value={formValues.alcoholConsumption}
          onChange={handleChange('alcoholConsumption')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('exerciseFrequency')}
          InputProps={{ startAdornment: <SportsFootballIcon /> }}
          value={formValues.exerciseFrequency}
          onChange={handleChange('exerciseFrequency')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('diet')}
          InputProps={{ startAdornment: <LocalDiningIcon /> }}
          value={formValues.diet}
          onChange={handleChange('diet')}
          fullWidth
          variant="outlined"
        />
      </Grid>







      <Grid item xs={12}>
        <h2>{t('extraInformation')}</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('languagePreference')}
          InputProps={{ startAdornment: <LanguageIcon /> }}
          value={formValues.languagePreference}
          onChange={handleChange('languagePreference')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('primaryDoctor')}
          InputProps={{ startAdornment: <LocalHospitalIcon /> }}
          value={formValues.primaryDoctor}
          onChange={handleChange('primaryDoctor')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('advancedDirectives')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.advancedDirectives.map((directive, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={directive}
                  onChange={handleAdvancedDirectiveChange(index)}
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={addAdvancedDirectives} variant="contained" color="primary">
        {t('addAdvancedDirectives')}
      </Button>
    </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('notes')}
          InputProps={{ startAdornment: <NotesIcon /> }}
          value={formValues.notes}
          onChange={handleChange('notes')}
          fullWidth
          variant="outlined"
        />






      </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
            >
              {t('createPatient')}
            </Button>
          </Grid>
        </Grid>
      </form> */}

