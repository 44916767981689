import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import metaReducer from '../redux/features/general/meta';
import notificationReducer from '../redux/features/general/notification'; 
import navigationReducer from '../redux/features/general/navigation';
import popupRedcuer from '../redux/features/general/popup'; 
import createSupportTicketReducer from '../redux/features/general/createSupportTicket'; 

import doctorAccountReducer from '../redux/features/doctor/Account/account';
import fileExplorerReducer from './features/doctor/Dashboard/documents/fileExplorer';
import overviewReducer from './features/doctor/Dashboard/documents/overview'; 
import createFolderReducer from './features/doctor/Dashboard/documents/createFolder'; 
import patientReducer from '../redux/features/doctor/Dashboard/patients/patient';
import patientListReducer from '../redux/features/doctor/Dashboard/patients/patientList';
import createPatientReducer from '../redux/features/doctor/Dashboard/patients/createPatient';
import transferFileReducer from '../redux/features/doctor/Dashboard/documents/transferFile';
import correctFileReducer from '../redux/features/doctor/Dashboard/documents/correctFile';
import uploadFileReducer from '../redux/features/doctor/Dashboard/documents/uploadFileSlice';
import adminCreateTeamReducer from '../redux/features/admin/dashboard/team/createTeam';
import adminCreateUserReducer from '../redux/features/admin/dashboard/users/createUser';
import adminUserAccessReducer from '../redux/features/admin/dashboard/users/userAccess';
import adminNoteReducer from '../redux/features/admin/dashboard/documents/note';
import adminCreateNoteReducer from '../redux/features/admin/dashboard/documents/createNote';
import adminRefreshNotesReducer from '../redux/features/admin/dashboard/documents/refreshNotes';
import adminFileReducer from '../redux/features/admin/dashboard/documents/file';
import adminRefreshFilesReducer from '../redux/features/admin/dashboard/documents/refreshFiles';
import adminConfirmationReducer from '../redux/features/admin/account/confirmation';

import createDoctorReducer from './features/hacker/users/createDoctor';
import createHackerReducer from './features/hacker/users/createHacker';
import supportTicketReducer from './features/support/supportTicket';
import supportEmailReducer from './features/support/supportEmail'; 

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, metaReducer);

export const store = configureStore({
  reducer: {
    meta: persistedReducer,
    notification: notificationReducer, 
    navigation: navigationReducer, 
    popup: popupRedcuer, 
    createSupportTicket: createSupportTicketReducer, 

    createDoctor: createDoctorReducer, 
    createHacker: createHackerReducer, 
    supportTicket: supportTicketReducer, 
    supportEmail: supportEmailReducer,

    doctorAccount: doctorAccountReducer,
    overview: overviewReducer, 
    fileExplorer: fileExplorerReducer,
    createFolder: createFolderReducer, 
    patient: patientReducer,
    patientList: patientListReducer,
    createPatient: createPatientReducer, 
    transferFile: transferFileReducer,
    correctFile: correctFileReducer,
    uploadFile: uploadFileReducer,
    adminCreateTeam: adminCreateTeamReducer,
    adminCreateUser: adminCreateUserReducer,
    adminUserAccess: adminUserAccessReducer,
    adminNote: adminNoteReducer,
    adminCreateNote: adminCreateNoteReducer,
    adminRefreshNotes: adminRefreshNotesReducer,
    adminFile: adminFileReducer,
    adminRefreshFiles: adminRefreshFilesReducer,
    adminConfirmation: adminConfirmationReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];