import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';

// -- Test Example ()

import titleExamples from './titleExamples';


type Props = {
    number: number
};

const Template: React.FC<Props> = ({ number }) => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '10vw',
        height: '30vh',
        paddingTop: '3vh', 
        paddingBottom: '3vh', 
        paddingLeft: '2vh',
        paddingRight: '2vh',
        borderRadius: 5,
        cursor: 'pointer',
        color: '#a6a6a6',
        fontSize: 12,
        '&:hover': { backgroundColor: 'rgba(77, 75, 89,0.3)', color: '#e0e0e0' }
    }}>

    <Box
      component="img"
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 2,
      }}
      alt="Solutions Medca Inc."
      src={require(`../../../../../../assets/templates/template_${number}.png`)}
    />

      <div style={{ marginTop: 2 }}>{titleExamples[number].title}</div>

    </Box>

  );

};

export default Template;